import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {TimePipe} from '../pipes/time.pipe';

@Injectable({
    providedIn: 'root'
})
export class PdfService {
    logoUrl = '../../assets/images/logo-main.png';
    logoImg = new Image();
    docDefinition;
    canvas;

    constructor(private datePipe: DatePipe,
                private timePipe: TimePipe) {
        this.logoImg.src = this.logoUrl;
    }

    graphPDF(graph) {
        this.docDefinition = {
            content: [
                {
                    image: this.getGraph(graph),
                    width: 500
                }
            ]
        };
        window['pdfMake'].createPdf(this.docDefinition).download('criticalVariables.PDF');
    }

    mainPDF(data) {
        console.log(data);
        this.docDefinition = {
            footer: function (currentPage, pageCount) {
                return [{
                    text: [
                        {
                            text: 'Proyecto N° IT16I10096 - Simulador basado en video juego para respuesta a desastres',
                            alignment: 'center'
                        },
                        {text: '\nPágina ' + currentPage.toString() + ' de ' + pageCount, alignment: 'center'}
                    ]
                }];
            },
            content: [
                {
                    columns: [
                        {
                            width: '*',
                            text: [
                                {
                                    text: 'Simulador basado en video juego para respuesta a desastres\n',
                                    bold: true
                                },
                                'Proyecto N° IT16I10096\n']
                        },
                        {
                            width: 70,
                            image: this.getLogoImage()
                        }
                    ],
                    columnGap: 10
                },
                {
                    text: [
                        {
                            text: '\nRESULTADO DE LA SIMULACIÓN',
                            style: 'header'
                        },
                        {
                            text: '\n' + data.simulation.name + '\n\n',
                            style: 'subHeader'
                        }
                    ]
                },
                '\n',
                {
                    columns: [
                        {
                            width: '*',
                            text: [
                                {text: 'Fecha de Inicio: ', bold: true},
                                this.datePipe.transform(data.simulation.startTime, 'medium') + '\n',
                            ]
                        },
                        {
                            width: '*',
                            text: [
                                {text: 'Fecha de Termino: ', bold: true},
                                this.datePipe.transform(data.simulation.endTime, 'medium') + '\n',
                            ]
                        }
                    ],
                    columnGap: 10
                },
                {
                    columns: [
                        {
                            width: '*',
                            text: [
                                {text: 'Duración de la simulación: ', bold: true},
                                this.timePipe.transform(data.simulation.scriptTime) + '\n',
                            ],
                            marginTop: 4
                        },
                    ],
                    columnGap: 10
                },
                '\n',
                {
                    text: [
                        {
                            text: 'Nombre del Guión: ',
                            bold: true
                        },
                        data.simulation.scriptName + '\n'
                    ]
                },
                {
                    text: [
                        {
                            text: 'Descripción: ',
                            bold: true
                        },
                        data.simulation.scriptDescription + '\n'
                    ]
                },
                '\n',
                {
                    text: 'CONDICIONES INICIALES\n\n',
                    bold: true
                },
                {
                    image: this.getGraph('environmentalConditionsGraphPDF'),
                    width: 500
                },
                '\n\n',
                {
                    text: 'METRICAS DE RENDIMIENTO\n\n',
                    bold: true,
                    pageBreak: 'before'
                },
                {
                    image: this.getGraph('criticalVariablesGraphPDF'),
                    width: 500
                },
                '\n',
                {
                    image: this.getGraph('impactVariablesGraphPDF'),
                    width: 500
                },
                '\n',
                {
                    image: this.getGraph('environmentalVariablesGraphPDF'),
                    width: 500
                }
            ],
            styles: {
                header: {
                    alignment: 'center',
                    fontSize: 18,
                    bold: true
                },
                subHeader: {
                    alignment: 'center',
                    fontSize: 16
                }
            }
        };
        window['pdfMake'].createPdf(this.docDefinition).download('main_data.PDF');
    }

    getLogoImage() {
        const image = this.logoImg;
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.getContext('2d').drawImage(image, 0, 0);
        return canvas.toDataURL('image/png');
    }

    getGraph(graph) {
        this.canvas = document.getElementById(graph);
        console.log(this.canvas);
        return this.canvas.toDataURL('image/png');
    }
}
