import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AgmCoreModule} from '@agm/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MaterialModule} from './modules/material/material.module';
import {ToolbarComponent} from './components/partials/toolbar/toolbar.component';
import {FooterComponent} from './components/partials/footer/footer.component';
import {LoginComponent} from './components/login/login.component';
import {PlatformComponent} from './components/platform/platform.component';
import {HomeComponent} from './components/platform/home/home.component';
import {DisasterComponent} from './components/platform/disaster/disaster.component';
import {UsersComponent} from './components/platform/users/users.component';
import {SimulationsComponent} from './components/platform/disaster/simulations/simulations.component';
import {SimulationComponent} from './components/platform/disaster/simulations/simulation/simulation.component';

import {NewSimulationComponent} from './components/platform/disaster/simulations/new-simulation/new-simulation.component';
import {RoleSimulationComponent} from './components/platform/disaster/simulations/dialogs/role-simulation/role-simulation.component';
import {
    TimerSimulationComponent
} from './components/platform/disaster/simulations/simulation/dialogs/timer-simulation/timer-simulation.component';
import {
    AlternativesSimulationComponent
} from './components/platform/disaster/simulations/simulation/dialogs/alternatives-simulation/alternatives-simulation.component';
import {ScriptsComponent} from './components/platform/disaster/scripts/scripts.component';
import {ScriptComponent} from './components/platform/disaster/scripts/script/script.component';
import {NewScriptComponent} from './components/platform/disaster/scripts/new-script/new-script.component';
import {EditScriptComponent} from './components/platform/disaster/scripts/dialogs/edit-script/edit-script.component';
import {RemoveScriptComponent} from './components/platform/disaster/scripts/dialogs/remove-script/remove-script.component';
import {NewEventScriptComponent} from './components/platform/disaster/scripts/dialogs/new-event-script/new-event-script.component';
import {EditEventScriptComponent} from './components/platform/disaster/scripts/dialogs/edit-event-script/edit-event-script.component';
import {
    RemoveEventScriptComponent
} from './components/platform/disaster/scripts/dialogs/remove-event-script/remove-event-script.component';
import {
    NewAlternativeEventScriptComponent
} from './components/platform/disaster/scripts/dialogs/new-alternative-event-script/new-alternative-event-script.component';
import {
    EditAlternativeEventScriptComponent
} from './components/platform/disaster/scripts/dialogs/edit-alternative-event-script/edit-alternative-event-script.component';
import {
    RemoveAlternativeEventScriptComponent
} from './components/platform/disaster/scripts/dialogs/remove-alternative-event-script/remove-alternative-event-script.component';
import {EventsComponent} from './components/platform/disaster/events/events.component';
import {ProfilesComponent} from './components/platform/disaster/profiles/profiles.component';
import {ProfileComponent} from './components/platform/disaster/profiles/profile/profile.component';
import {ResultsComponent} from './components/platform/disaster/results/results.component';
import {ResultComponent} from './components/platform/disaster/results/result/result.component';
import {NewUserComponent} from './components/platform/users/dialogs/new-user/new-user.component';
import {EditUserComponent} from './components/platform/users/dialogs/edit-user/edit-user.component';
import {RemoveUserComponent} from './components/platform/users/dialogs/remove-user/remove-user.component';
import {UrlYoutubePipe} from './pipes/url-youtube.pipe';
import {TimePipe} from './pipes/time.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MatNativeDateModule, MatPaginatorIntl} from '@angular/material';
import {ApiService} from './services/api.service';
import {AuthService} from './services/auth.service';
import {PdfService} from './services/pdf.service';
import {CurrencyPipe, DatePipe, registerLocaleData} from '@angular/common';
import {MatPaginatorCustom} from './custom/custom-paginator';
import localeEs from '@angular/common/locales/es';
import {AdminGuard} from './guards/admin.guard';
import {AuthGuard} from './guards/auth.guard';
import {ModeratorGuard} from './guards/moderator.guard';
import { DisasterPipe } from './pipes/disaster.pipe';
import { NewEventComponent } from './components/platform/disaster/events/dialogs/new-event/new-event.component';
import { EditEventComponent } from './components/platform/disaster/events/dialogs/edit-event/edit-event.component';
import { RemoveEventComponent } from './components/platform/disaster/events/dialogs/remove-event/remove-event.component';
import { NewProfileComponent } from './components/platform/disaster/profiles/new-profile/new-profile.component';
import {CookieService} from 'ngx-cookie-service';
import { VariablesComponent } from './components/platform/disaster/variables/variables.component';
import { NewVariableComponent } from './components/platform/disaster/variables/dialogs/new-variable/new-variable.component';
import { EditVariableComponent } from './components/platform/disaster/variables/dialogs/edit-variable/edit-variable.component';
import { RemoveVariableComponent } from './components/platform/disaster/variables/dialogs/remove-variable/remove-variable.component';
import {ChatComponent} from './components/platform/disaster/simulations/simulation/chat/chat.component';
import { RemoveProfileComponent } from './components/platform/disaster/profiles/dialogs/remove-profile/remove-profile.component';

import {DialogOverviewExampleDialog} from './components/platform/disaster/simulations/simulation/simulation.component';

import {ConditionsPipe} from './pipes/conditionsTransform.pipe';
import {ConditionsVarPipe} from './pipes/conditionsVarTransform.pipe';


registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
        ToolbarComponent,
        FooterComponent,
        LoginComponent,
        HomeComponent,
        DisasterComponent,
        PlatformComponent,
        UsersComponent,
        NewUserComponent,
        EditUserComponent,
        RemoveUserComponent,
        SimulationsComponent,
        SimulationComponent,
        DialogOverviewExampleDialog,
        NewSimulationComponent,
        RoleSimulationComponent,
        TimerSimulationComponent,
        AlternativesSimulationComponent,
        ScriptsComponent,
        ScriptComponent,
        NewScriptComponent,
        EditScriptComponent,
        RemoveScriptComponent,
        NewEventScriptComponent,
        EditEventScriptComponent,
        RemoveEventScriptComponent,
        NewAlternativeEventScriptComponent,
        EditAlternativeEventScriptComponent,
        RemoveAlternativeEventScriptComponent,
        EventsComponent,
        NewEventComponent,
        EditEventComponent,
        RemoveEventComponent,
        ProfilesComponent,
        ProfileComponent,
        ResultsComponent,
        ResultComponent,
        UrlYoutubePipe,
        TimePipe,
        DisasterPipe,
        NewProfileComponent,
        VariablesComponent,
        NewVariableComponent,
        EditVariableComponent,
        RemoveVariableComponent,
        ChatComponent,
        RemoveProfileComponent,
        ConditionsVarPipe,
        ConditionsPipe
    ],
    entryComponents: [
        NewUserComponent,
        EditUserComponent,
        RemoveUserComponent,
        RoleSimulationComponent,
        TimerSimulationComponent,
        AlternativesSimulationComponent,
        EditScriptComponent,
        RemoveScriptComponent,
        NewEventScriptComponent,
        EditEventScriptComponent,
        RemoveEventScriptComponent,
        NewAlternativeEventScriptComponent,
        EditAlternativeEventScriptComponent,
        RemoveAlternativeEventScriptComponent,
        NewEventComponent,
        EditEventComponent,
        RemoveEventComponent,
        NewVariableComponent,
        EditVariableComponent,
        RemoveVariableComponent,
        RemoveProfileComponent,
        DialogOverviewExampleDialog,
        ChatComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatNativeDateModule,
        AppRoutingModule,
        MaterialModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDOj_zwTIBJl2qPfloo9zhcvebHL1Cg7c0'
        })
    ],
    providers: [
        ApiService,
        AuthService,
        PdfService,
        CookieService,
        AuthGuard,
        AdminGuard,
        ModeratorGuard,
        CurrencyPipe,
        DatePipe,
        TimePipe,
        DisasterPipe,
        {provide: 'Window', useValue: window}, // for Unity
        {provide: LOCALE_ID, useValue: 'es'},
        {provide: MatPaginatorIntl, useClass: MatPaginatorCustom},
        BrowserAnimationsModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
