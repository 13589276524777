import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import * as CryptoJS from 'crypto-js';
import {CookieService} from "ngx-cookie-service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginFormGroup: FormGroup;
    loginError = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                private formBuilder: FormBuilder,
                private authService: AuthService,
                private cookieService: CookieService) {
        this.loginFormGroup = this.formBuilder.group({
            userCtrl: ['', Validators.required],
            passwordCtrl: ['', Validators.required]
        });
    }

    ngOnInit() {
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    login() {
        if (this.loginFormGroup.valid) {
            const loginUser = {
                Username: this.loginFormGroup.get('userCtrl').value,
                Password: this.loginFormGroup.get('passwordCtrl').value
            };
            this.authService.login(loginUser).subscribe(response => {
                    if (response.success) {
                        this.cookieService.set(
                            'token',
                            CryptoJS.AES.encrypt(response.data.token, 'Fondef N° IT16I10096')
                        );
                        this.router.navigate(['platform'], {relativeTo: this.route}).then();
                        this.openSnackBar(response.message, 'Aceptar');
                    } else {
                        this.loginError = true;
                        this.openSnackBar(response.message, 'Aceptar');
                    }
                }, error => {
                    if (error) {
                        this.loginError = true;
                        this.openSnackBar('Error al intentar ingresar', 'Aceptar');
                    }
                }
            );
        }
    }

}
