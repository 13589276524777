import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-remove-event',
    templateUrl: './remove-event.component.html',
    styleUrls: ['./remove-event.component.scss']
})
export class RemoveEventComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<RemoveEventComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
    }

    deleteEvent() {
        this.dialogRef.close(true);
    }

}
