import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../../../../../models/User';
import {ApiService} from '../../../../../services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-remove-user',
    templateUrl: './remove-user.component.html',
    styleUrls: ['./remove-user.component.scss']
})
export class RemoveUserComponent implements OnInit {
    user: User;

    constructor(public dialogRef: MatDialogRef<RemoveUserComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private snackBar: MatSnackBar) {
        this.dialogRef.disableClose = true;
        this.user = data.user;
    }

    ngOnInit() {
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    deleteUser() {
        this.apiService.deleteUser(this.user.id).subscribe(response => {
            if (response.success) {
                this.openSnackBar('El usuario ha sido eliminado con éxito', 'Aceptar');
                this.dialogRef.close(true);
            } else {
                this.openSnackBar('No se ha podido remover el usuario', 'Aceptar');
            }
        }, error => {
            if (error) {
                this.openSnackBar('No se ha podido remover el usuario (ERROR)', 'Aceptar');
            }
        });
    }

}
