import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {DisasterPipe} from '../../../../pipes/disaster.pipe';
import {Profile} from '../../../../models/Profile';
import {RemoveVariableComponent} from '../variables/dialogs/remove-variable/remove-variable.component';
import {RemoveProfileComponent} from './dialogs/remove-profile/remove-profile.component';

@Component({
    selector: 'app-profiles',
    templateUrl: './profiles.component.html',
    styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {
    title = '';
    disaster;
    disasterId;
    filter = '';
    displayedColumns = ['row', 'name', 'scenarioName', 'action'];
    dataSource;
    profiles: Profile[] = [];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private apiService: ApiService,
                private snackBar: MatSnackBar,
                private disasterPipe: DisasterPipe) {
        this.title = this.route.snapshot.data.title;
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.disaster, 'id');
    }

    ngOnInit() {
        this.apiService.getProfilesByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.profiles = response.data;
                this.dataSource = new MatTableDataSource<Profile>(this.profiles);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                console.log(this.profiles);
            }
        }, error => {
            if (error) {
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    deleteProfile(id) {
        const dialogRef = this.dialog.open(RemoveProfileComponent, {
            maxWidth: '650px',
            width: '98%',
            autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.apiService.deleteProfile(id).subscribe(response => {
                    if (response.success) {
                        this.openSnackBar(response.message, 'Aceptar');
                        this.ngOnInit();
                    } else {
                        this.openSnackBar(response.message, 'Aceptar');
                    }
                }, error => {
                    if (error) {
                        this.openSnackBar('No se ha podido remover la variable', 'Aceptar');
                    }
                });
            }
        });
    }

}
