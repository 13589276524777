import {Component, OnInit, ViewChild, Input } from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {Simulation} from '../../../../models/Simulation';
import {RoleSimulationComponent} from './dialogs/role-simulation/role-simulation.component';
import {DisasterPipe} from '../../../../pipes/disaster.pipe';
import {AuthService} from '../../../../services/auth.service';
import {SimulationRole} from '../../../../models/SimulationRole';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-simulations',
    templateUrl: './simulations.component.html',
    styleUrls: ['./simulations.component.scss']
})
export class SimulationsComponent implements OnInit {
    title = '';
    filter = '';
    displayedColumns = ['row', 'name', 'scriptName', 'startDate', 'statusName', 'action'];
    dataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    disaster;
    disasterId;
    simulations: Simulation[] = [];
    assignedUsers = false;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private apiService: ApiService,
                private authService: AuthService,
                private disasterPipe: DisasterPipe) {
        this.title = this.route.snapshot.data.title;
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.disaster, 'id');
    }

    ngOnInit() {
        this.apiService.getSimulationsByDisasterInProgress(this.disasterId).subscribe(response => {
            console.log(response.data);
            if (response.success) {
               
                this.simulations = response.data;
                this.dataSource = new MatTableDataSource<Simulation>(this.simulations);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }, error => {
            if (error) {
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    simulationAccess(simulationId, simulationIdTmp) {
        console.log(simulationId);
        console.log(simulationIdTmp);
        this.apiService.getSimulation(simulationId).subscribe(response => {
            if (response.success) {
                if (response.data.predefinedUsers !== 1) {
                    const dialogRef = this.dialog.open(RoleSimulationComponent, {
                        maxWidth: '600px',
                        width: '95%',
                        autoFocus: false,
                        data: {
                            simulationId: simulationId
                        }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            this.router.navigate([simulationId], {relativeTo: this.route}).then();
                        }
                    });
                } else {
                    this.authService.getUser().subscribe(response => {
                        if (response.success) {
                            this.apiService.getSimulationRoleBySimulationAndUser(simulationId, response.data.id).subscribe(response2 => {
                                if (response2.success) {
                                    this.openSnackBar('Se ha permitido el acceso a la simulación', 'Aceptar');
                                    this.router.navigate([simulationId], {relativeTo: this.route}).then();
                                } else {
                                    if (response.data.roleId === 1 || response.data.roleId === 2) {
                                        const simulationRole: SimulationRole = {
                                            simulationId: simulationId,
                                            moderator: 1,
                                            simulationRoleCommandId: 1,
                                            simulationRoleLevelId: 1,
                                            userId: response.data.id
                                        };

                                        this.apiService.createSimulationRole(simulationRole).subscribe(response => {
                                            if (response.success) {
                                                this.router.navigate([simulationId], {relativeTo: this.route}).then();
                                                this.openSnackBar('Acceso a la simulación como moderador', 'Aceptar');
                                            } else {
                                                this.openSnackBar('No tienes permitido el acceso a esta simulación', 'Aceptar');
                                            }
                                        }, error => {
                                            if (error) {
                                                this.openSnackBar('No tienes permitido el acceso a esta simulación', 'Aceptar');
                                            }
                                        });


                                    } else {
                                        this.openSnackBar('No tienes permitido el acceso a esta simulación', 'Aceptar');
                                    }
                                }
                            }, error => {
                                if (error) {
                                    this.openSnackBar('No se ha podido acceder a esta simulación', 'Aceptar');
                                }
                            });
                        }
                    });
                }
            }
        }, error => {
            if (error) {
            }
        });
    }

}
