import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import * as CryptoJS from 'crypto-js';
import {CookieService} from "ngx-cookie-service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    // URL_BASE = 'http://vjs.cl:5000/api/';

    //URL_BASE = 'http://164.77.114.239:5000/api/';
    URL_BASE = 'https://vjs.cl:5000/api/';
    constructor(private http: HttpClient,
                private router: Router,
                private cookieService: CookieService) {
    }

    login(user): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this.http.post(this.URL_BASE + 'Auth/Login', user, {headers});
    }

    validate(): Observable<any> {
        let token = CryptoJS.AES.decrypt(this.cookieService.get('token'), 'Fondef N° IT16I10096').toString(CryptoJS.enc.Utf8);
        token = 'Bearer ' + token;
        const headers = new HttpHeaders().set('Authorization', token);
        return this.http.get(this.URL_BASE + 'Auth/Validate', {headers});
    }

    getUser(): Observable<any> {
        let token = CryptoJS.AES.decrypt(this.cookieService.get('token'), 'Fondef N° IT16I10096').toString(CryptoJS.enc.Utf8);
        token = 'Bearer ' + token;
        const headers = new HttpHeaders().set('Authorization', token);
        return this.http.get(this.URL_BASE + 'Auth/User/', {headers});
    }

    logout() {
        this.cookieService.deleteAll();
        this.cookieService.deleteAll('/');
        this.router.navigateByUrl('/login').then();
    }
}
