import {Injectable} from '@angular/core';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';

const FULL_LOADED = 3;

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    public id: string;
    loaded = 0;
    allLoaded = false;

    ws: $WebSocket;
    broadcasting: $WebSocket;

    constructor() {

    }

    connectSocket(port=7000) {
        try {
            this.ws = new $WebSocket('wss://' + document.location.hostname + ':' + port + '/ws1', null, {
                initialTimeout: 500,
                maxTimeout: 300000,
                reconnectIfNotNormalClose: true
            });

            this.ws.onMessage(
                (msg: MessageEvent) => {
                    const data = JSON.parse(msg.data);

                    // si es de tipo 1 es el primer dato despues de establecer la conexión
                    if (data.type === 1) {
                        this.loaded++;
                        this.id = data.id;
                    }
                    this.getValues(data);
                }
            );

            this.ws.onClose(() => {
                this.loaded = FULL_LOADED - 1;
            });

        } catch (e) {
            setTimeout(() => {
                this.connectSocket(port);
            }, 1000);
        }
    }

    connectSocketBroadcasting(port=7001) {
        try {
            this.broadcasting = new $WebSocket("wss://" + document.location.hostname + ":" + port + "/broadcasting1", null, {
                initialTimeout: 500,
                maxTimeout: 300000,
                reconnectIfNotNormalClose: true
            });
            //this.broadcasting = new $WebSocket("ws://164.77.114.239:5777/broadcasting", null, {initialTimeout:500, maxTimeout:300000, reconnectIfNotNormalClose:true});
            //this.n_cells = 0;
            //for function access
            let scope = this;
            this.broadcasting.onMessage(
                (msg: MessageEvent) => {
                    //data received here
                    let b = new Blob(Array.from(msg.data));
                    /* var fr = new FileReader();
                     fr.onload = function() {
                         var byteStates = new Int8Array(fr.result);

                         if(byteStates.length==0){
                           byteStates[0]=0;
                           byteStates[1]=0;
                        }

                     };
                     fr.readAsArrayBuffer(b);*/
                    this.getValues2(b);
                }
            );
            this.broadcasting.onClose(() => {

            });

        } catch (e) {
            setTimeout(() => {
                this.connectSocketBroadcasting(port);
            }, 1000);
        }
    }

    getValues(data) {
    }

    getValues2(b) {
    }

    setValues(data) {
        console.log("data set send: " + data);
        return this.ws.send(JSON.stringify(data));
    }
    closews(){
        this.ws.close();
        this.broadcasting.close();
    }
}
