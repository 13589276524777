import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../../../../../services/api.service';
import {EventScriptAlternative, EventScriptDependence} from "../../../../../../models/EventScript";

@Component({
    selector: 'app-edit-alternative-event-script',
    templateUrl: './edit-alternative-event-script.component.html',
    styleUrls: ['./edit-alternative-event-script.component.scss']
})
export class EditAlternativeEventScriptComponent implements OnInit {

    disasterId;
    alternative: EventScriptAlternative;
    dependencies: EventScriptDependence[] = [];

    constructor(public dialogRef: MatDialogRef<EditAlternativeEventScriptComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService) {
        this.disasterId = this.data.disasterId;
        dialogRef.disableClose = true;
        this.alternative = data.alternative;
        this.apiService.getVariablesByDisaster(this.disasterId).subscribe(response2 => {
            if (response2.success) {
                this.dependencies = response2.data;
                for (let i = 0; i < this.alternative.dependencies.length; i++) {
                    for (let j = 0; j < this.dependencies.length; j++) {
                        if (this.alternative.dependencies[i].id === this.dependencies[j].id) {
                            this.dependencies[j] = this.alternative.dependencies[i];
                        }
                    }
                }
            }
        }, error2 => {
            if (error2) {
            }
        });
    }

    ngOnInit() {
    }

    newAlternative() {
        this.dialogRef.close(this.alternative);
    }

}
