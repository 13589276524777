import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name:"conditionsVarTransform"
  })

  export class ConditionsVarPipe implements PipeTransform{
    transform(value:string) : string{
      let condFormatt="";
      switch(value){
        
        case 'temperature':
            condFormatt="Temperatura";
        break;
        
        case 'humedity':
            condFormatt="Humedad";
        break;

        case 'wind_speed':
            condFormatt="Velocidad Viento";
        break;

        case 'wind_direction':
            condFormatt="Dirección Viento";
        break;

        case 'pressure':
            condFormatt="Presión";
        break;

      }
      
      return condFormatt;
    }
  }
