import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VariableGroup} from '../../../../../../models/VariableGroup';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../../../../../services/api.service';
import {Variable} from '../../../../../../models/Variable';

@Component({
    selector: 'app-new-variable',
    templateUrl: './new-variable.component.html',
    styleUrls: ['./new-variable.component.scss']
})
export class NewVariableComponent implements OnInit {
    variableFormGroup: FormGroup;
    variableGroups: VariableGroup[] = [];
    variable: Variable;
    variables: Variable[] = [];
    variablesSelected = [];
    disasterId;

    constructor(public dialogRef: MatDialogRef<NewVariableComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private formBuilder: FormBuilder) {
        this.dialogRef.disableClose = true;
        this.variableFormGroup = this.formBuilder.group({
            nameCtrl: ['', Validators.required],
            variableGroupCtrl: ['', Validators.required],
            valueCtrl: ['', Validators.required],
            weightCtrl: ['', Validators.required]
        });
        this.disasterId = data.disasterId;
    }

    ngOnInit() {
        this.apiService.getVariableGroupsByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.variableGroups = response.data;
            } else {
                this.dialogRef.close();
            }
        }, error => {
            if (error) {
                this.dialogRef.close();
            }
        });
        this.apiService.getVariablesByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.variables = response.data;
            }
        });
    }

    createVariable() {
        if (this.variableFormGroup.valid) {
            const variable: Variable = {
                name: this.variableFormGroup.get('nameCtrl').value,
                value: this.variableFormGroup.get('valueCtrl').value,
                weight: this.variableFormGroup.get('weightCtrl').value,
                ecRel: JSON.stringify(this.variablesSelected),
                disasterId: this.data.disasterId,
                variableGroupId: this.variableFormGroup.get('variableGroupCtrl').value
            };
            this.dialogRef.close({
                data: variable
            });
        }
    }

}
