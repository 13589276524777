import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../../services/api.service';
import {AuthService} from '../../../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PdfService} from '../../../../../services/pdf.service';
import * as Chart from 'chart.js';
import {TimePipe} from '../../../../../pipes/time.pipe';
import {Simulation} from "../../../../../models/Simulation";
import {Script} from "../../../../../models/Script";
import {Profile} from "../../../../../models/Profile";
import {Variable} from "../../../../../models/Variable";
import {VariableGroup} from "../../../../../models/VariableGroup";
import {DisasterPipe} from "../../../../../pipes/disaster.pipe";
import {MatDialog, MatTableDataSource} from '@angular/material';
import {AlternativesSimulationComponent} from '../../simulations/simulation/dialogs/alternatives-simulation/alternatives-simulation.component';

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
    title = '';
    simulationId;
    simulation: Simulation;
    script: Script;
    profile: Profile;
    variables: Variable[] = [];
    variablesGroups: VariableGroup[] = [];
    simulationLogs = [];
    answers = [];
    timeType = 'minutes';
    disasterType;
    disasterId;

    user;

    private evaluationValues = [
        {id: 1, name: "Muy Desacuerdo"},
        {id: 2, name: "Desacuerdo"},
        {id: 3, name: "Regular"},
        {id: 4, name: "De Acuerdo"},
        {id: 5, name: "Muy de Acuerdo"}
      ];

    displayedColumns = ['row', 'own','valueEvaluated', 'evaluatedBy', 'valueReEvaluated','reEvaluatedBy', 'created', 'action'];
    dataSource;

    environmentalConditionsChartPDF;
    @ViewChild('environmentalConditionsGraphPDF') environmentalConditionsGraphPDF;
    criticalVariablesChartPDF;
    @ViewChild('criticalVariablesGraphPDF') criticalVariablesGraphPDF;
    impactVariablesChartPDF;
    @ViewChild('impactVariablesGraphPDF') impactVariablesGraphPDF;
    environmentalVariablesChartPDF;
    @ViewChild('environmentalVariablesGraphPDF') environmentalVariablesGraphPDF;

    // Gráfico de Condiciones ambientales
    environmentalConditionsChart;
    @ViewChild('environmentalConditionsGraph') environmentalConditionsGraph;
    // Gráficos de Variables
    criticalVariablesChart;
    @ViewChild('criticalVariablesGraph') criticalVariablesGraph;
    impactVariablesChart;
    @ViewChild('impactVariablesGraph') impactVariablesGraph;
    environmentalVariablesChart;
    @ViewChild('environmentalVariablesGraph') environmentalVariablesGraph;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private apiService: ApiService,
                private pdfService: PdfService,
                public timePipe: TimePipe,
                private auth: AuthService,
                private _dialog: MatDialog,
                private disasterPipe: DisasterPipe) {
        this.title = this.route.snapshot.data.title;
        this.simulationId = this.route.snapshot.params.id;
        this.disasterType = this.route.parent.parent.snapshot.params.type;
        this.disasterId = this.disasterPipe.transform(this.disasterType, 'id');

        this.auth.getUser().subscribe(response => {
            if (response.success) {
                this.user = response.data;
            }
        }, error => {
            console.log(error);
        });
    }

    ngOnInit() {
        this.apiService.getSimulation(this.simulationId).subscribe(response => {
            if (response.success) {
                this.simulation = response.data;
                this.apiService.getScript(this.simulation.scriptId).subscribe(response2 => {
                    if (response2.success) {
                        this.script = response2.data;
                        this.apiService.getProfile(this.script.profileId).subscribe(response3 => {
                            if (response3.success) {
                                this.profile = response3.data;
                                this.profile.environments = JSON.parse(this.profile.environments);
                                /**
                                 * Se carga el grafico de condiciones ambientales
                                 * */

                            
                                this.enviromentalConditions();
                                this.apiService.getVariableGroups().subscribe(response4 => {
                                    if (response4.success) {
                                        this.variablesGroups = response4.data;
                                        // Obtener las variables relacionadas al desastre
                                        this.apiService.getVariablesByDisaster(this.disasterId).subscribe(response5 => {
                                            if (response5.success) {
                                                this.variables = response5.data;
                                                console.log(this.variables);
                                                this.criticalVariables();
                                                this.impactVariables();
                                                this.enviromentalVariables();
                                            }
                                        }, error5 => {
                                            if (error5) {
                                            }
                                        });
                                    }
                                }, error4 => {
                                    if (error4) {
                                    }
                                });
                            }
                        }, error3 => {
                            if (error3) {
                            }
                        });
                    }
                }, error2 => {
                    if (error2) {
                    }
                });
            }
        }, error => {
            if (error) {
            }
        });
        this.apiService.getSimulationLogsBySimulation(this.simulationId).subscribe(response => {
            if (response.success) {
                this.simulationLogs = response.data;
                //console.log(this.simulationLogs);
            }
        }, error => {
            if (error) {
            }
        });
        this.getAnswers();
    }

    refreshCharts() {
        this.criticalVariables();
        this.impactVariables();
        this.enviromentalVariables();
    }

    generateLabels() {
        const labels = [];
        const maxMinute = this.timePipe.transform(this.script.time, this.timeType);
        for (let i = 0; i <= maxMinute; i++) {
            labels.push(i);
        }
        return labels;
    }

    getAnswers(){
        this.apiService.getAlternativesFull(this.simulationId).subscribe(response => {
            if (response.success) {
                this.answers = response.data;
                for(let a of this.answers){
                    if(a.eventValue){
                        a.eventValue = JSON.parse(a.eventValue);
                    }
                    if(a.valueEvaluated > 0 && a.valueEvaluated <= this.evaluationValues.length){
                        a.valueEvaluatedObj = this.evaluationValues[a.valueEvaluated-1];
                    }
                    if(a.valueReEvaluated > 0 && a.valueReEvaluated <= this.evaluationValues.length){
                        a.valueReEvaluatedObj = this.evaluationValues[a.valueReEvaluated-1];
                    }
                    if(a.userRole){
                        a.userRoleObj = {};
                        if(a.userRole.simulationRoleCommandId == 1){a.userRoleObj.commandName = 'Tecnico';}
                        if(a.userRole.simulationRoleCommandId == 2){a.userRoleObj.commandName = 'Coordinador';}
                        if(a.userRole.simulationRoleCommandId == 3){a.userRoleObj.commandName = 'Autoridad';}
                        if(a.userRole.simulationRoleLevelId == 1){a.userRoleObj.levelName = 'Comunal';}
                        if(a.userRole.simulationRoleLevelId == 2){a.userRoleObj.levelName = 'Regional';}
                        if(a.userRole.simulationRoleLevelId == 3){a.userRoleObj.levelName = 'Nacional';}
                    }
                }
                this.dataSource = new MatTableDataSource(this.answers);
            }
        });
    }

    evaluate(a){
        this.openAlternativesDialog({edit: true, alternative: a, re: true}, []);
    }

    openAlternativesDialog(data, alternatives){
        var tmp = {};
        for(let i in data){
            tmp[i] = data[i];
        }
        tmp["user"] = this.user.id;
        tmp["question"] = data.alternative.eventName;
        tmp["done"] = () => {
            this.getAnswers();
        };
        const dialogRef = this._dialog.open(AlternativesSimulationComponent, {
            maxWidth: '650px',
            width: '98%',
            data: tmp
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    generateData(variableId) {
       
        const variableData = [];
        const finalData = [];
        for (let i = 0; i < this.simulationLogs.length; i++) {
            if (this.simulationLogs[i].variableId === variableId) {
             
                variableData.push(this.simulationLogs[i]);
            }
        }
        const dataByTime = [];
        const time = this.timePipe.transform(this.script.time, this.timeType);
        for (let i = 0; i <= time; i++) {
            dataByTime.push({
                time: i,
                data: []
            });
        }
        for (let i = 0; i < variableData.length; i++) {
            
            const logTime = Math.floor(this.timePipe.transform(variableData[i].time, this.timeType));
            for (let j = 0; j < dataByTime.length; j++) {
                if (logTime === dataByTime[j].time) {
                    dataByTime[j].data.push(variableData[i].value);
                }
            }
        }
        let lastValue = 0;
        for (let i = 0; i < dataByTime.length; i++) {
            let sumValues = 0;
            for (let j = 0; j < dataByTime[i].data.length; j++) {
                sumValues = sumValues + dataByTime[i].data[j];
            }
            if (sumValues !== 0) {
                lastValue = sumValues / dataByTime[i].data.length;
            }
            finalData.push(lastValue);
        }
        return finalData;
    }

    generateDataCriticalVariables(label) {
        let finalData;

        if(this.disasterId==3){
            switch (label) {
                
                case 'Condiciones Ambientales':
                    const temperature = this.generateData(this.variables[0].id);
                    const humidity = this.generateData(this.variables[1].id);
                    finalData = [];
                    for (let i = 0; i <= this.timePipe.transform(this.script.time, this.timeType); i++) {
                      
                        finalData.push(
                            (temperature[i] + humidity[i]) / 2
                        );
                    }
                    return finalData;
                case 'Impacto Desastre':
                    const heridos = this.generateData(this.variables[2].id);
                    const muertos = this.generateData(this.variables[3].id);
                    const seguridad = this.generateData(this.variables[4].id);
                    const salud = this.generateData(this.variables[5].id);
                    finalData = [];
                    for (let i = 0; i <= this.timePipe.transform(this.script.time, this.timeType); i++) {
                        finalData.push(
                            (heridos[i] + muertos[i] + seguridad[i] + salud[i]) / 4
                        );
                    }
                    return finalData;
            }
        }else{
            switch (label) {
                
                case 'Condiciones Ambientales':
                    const temperature = this.generateData(this.variables[8].id);
                    const pressure = this.generateData(this.variables[7].id);
                    const humidity = this.generateData(this.variables[6].id);
                    finalData = [];
                    for (let i = 0; i <= this.timePipe.transform(this.script.time, this.timeType); i++) {
                      
                        finalData.push(
                            (temperature[i] + pressure[i] + humidity[i]) / 3
                        );
                    }
                    return finalData;
                case 'Impacto Desastre':
                    const hectaresBurned = this.generateData(this.variables[5].id);
                    const deaths = this.generateData(this.variables[4].id);
                    const wounded = this.generateData(this.variables[3].id);
                    finalData = [];
                    for (let i = 0; i <= this.timePipe.transform(this.script.time, this.timeType); i++) {
                        finalData.push(
                            (hectaresBurned[i] + deaths[i] + wounded[i]) / 3
                        );
                    }
                    return finalData;
            }
        }
       
      
    }

    mainPDF() {
        const data = {
            simulation: {},
            simulationLog: {}
        };
        data.simulation = {
            name: this.simulation.name,
            startTime: this.simulation.startDate,
            endTime: this.simulation.endDate,
            scriptTime: this.script.time,
            scriptName: this.script.name,
            scriptDescription: this.script.description,
            profile: this.profile.name
        };
        this.pdfService.mainPDF(data);
    }

    enviromentalConditions() {
        const ctxEnvironmentalConditionsGraph = this.environmentalConditionsGraph.nativeElement.getContext('2d');
        const ctxPdfGraph = this.environmentalConditionsGraphPDF.nativeElement.getContext('2d');
       
       if(this.disasterId==3){
        const chartData = {
            type: 'horizontalBar',
            data: {
                labels: [
                    'Temperatura (C)',
                    'Humedad (%)'
                ],
                datasets: [{
                    label: 'Condiciones Iniciales',
                    data: [
                        this.profile.environments[0].valueEnvironment,
                        this.profile.environments[1].valueEnvironment
                        
                    ],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                maintainAspectRatio: false,
                aspectRatio: 0.9,
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            }
        };

        this.environmentalConditionsChart = new Chart(ctxEnvironmentalConditionsGraph, chartData);
        this.environmentalConditionsChartPDF = new Chart(ctxPdfGraph, chartData);

       }else{
        const chartData = {
            type: 'horizontalBar',
            data: {
                labels: [
                    'Temperatura (C)',
                    'Humedad (%)',
                    'Velocidad del Viento (Km/h)',
                    'Dirección del Viento (º)',
                    'Presión (%)'
                ],
                datasets: [{
                    label: 'Condiciones Iniciales',
                    data: [
                        this.profile.environments[0].valueEnvironment,
                        this.profile.environments[1].valueEnvironment,
                        this.profile.environments[2].valueEnvironment,
                        this.profile.environments[3].valueEnvironment,
                        this.profile.environments[4].valueEnvironment
                    ],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(0,128,128, 0.2)',
                        'rgba(210,105,30, 0.2)',
                        'rgba(112,128,144, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(0,128,128, 1)',
                        'rgba(210,105,30, 1)',
                        'rgba(112,128,144, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                maintainAspectRatio: false,
                aspectRatio: 0.9,
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            }
        };
        this.environmentalConditionsChart = new Chart(ctxEnvironmentalConditionsGraph, chartData);
        this.environmentalConditionsChartPDF = new Chart(ctxPdfGraph, chartData);
       }
        
      
    }

    criticalVariables() {
        const ctxCriticalVariablesGraph = this.criticalVariablesGraph.nativeElement.getContext('2d');
        const ctxPdfGraph = this.criticalVariablesGraphPDF.nativeElement.getContext('2d');
        const chartData = {
            type: 'line',
            data: {
                labels: this.generateLabels(),
                datasets: [
                    {
                        label: 'Condiciones Ambientales',
                        data: this.generateDataCriticalVariables('Condiciones Ambientales'),
                        borderColor: '#808080',
                        fill: false,
                        // pointRadius: 0.5,
                    },
                    {
                        label: 'Impacto Desastre',
                        data: this.generateDataCriticalVariables('Impacto Desastre'),
                        borderColor: '#000000',
                        fill: false,
                        // pointRadius: 0.5,
                    }
                ]
            },
            options: {
                title: {
                    display: true,
                    text: 'Grupo Variables Críticas'
                },
                legend: {
                    display: true
                },
                scales: {
                    xAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Tiempo Simulación'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Factor'
                        }
                    }],
                }
            }
        };
        this.criticalVariablesChart = new Chart(ctxCriticalVariablesGraph, chartData);
        this.criticalVariablesChartPDF = new Chart(ctxPdfGraph, chartData);
    }

    impactVariables() {
        const ctxImpactVariablesGraph = this.impactVariablesGraph.nativeElement.getContext('2d');
        const ctxPdfGraph = this.impactVariablesGraphPDF.nativeElement.getContext('2d');
        const chartData = {
            type: 'line',
            data: {
                labels: this.generateLabels(),
                datasets: [
                    /*{
                        label: this.variables[1].name,
                        data: this.generateData(this.variables[1].id),
                        borderColor: '#C0C0C0',
                        fill: false
                    },*/
                    {
                        label: this.variables[2].name,
                        data: this.generateData(this.variables[2].id),
                        borderColor: '#808080',
                        fill: false
                    },
                    {
                        label: this.variables[3].name,
                        data: this.generateData(this.variables[3].id),
                        borderColor: '#000000',
                        fill: false
                    },
                    {
                        label: this.variables[4].name,
                        data: this.generateData(this.variables[4].id),
                        borderColor: '#808080',
                        fill: false
                    },
                    {
                        label: this.variables[5].name,
                        data: this.generateData(this.variables[5].id),
                        borderColor: '#808080',
                        fill: false
                    },
                ]
            },
            options: {
                title: {
                    display: true,
                    text: 'Impacto Desastre'
                },
                legend: {
                    display: true
                },
                scales: {
                    xAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Tiempo Simulación'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Factor de impacto'
                        }
                    }]
                }
            }
        };
        this.impactVariablesChart = new Chart(ctxImpactVariablesGraph, chartData);
        this.impactVariablesChartPDF = new Chart(ctxPdfGraph, chartData);
    }

    enviromentalVariables() {
        const ctxEnviromentalVariablesGraph = this.environmentalVariablesGraph.nativeElement.getContext('2d');
        const ctxPdfGraph = this.environmentalVariablesGraphPDF.nativeElement.getContext('2d');
        
        if(this.disasterId==3){
            const chartData = {
                type: 'line',
                data: {
                    labels: this.generateLabels(),
                    datasets: [
                        {
                            label: this.variables[0].name,
                            data: this.generateData(this.variables[0].id),
                            borderColor: '#C0C0C0',
                            fill: false
                        },
                        {
                            label: this.variables[1].name,
                            data: this.generateData(this.variables[1].id),
                            borderColor: '#808080',
                            fill: false
                        },
                       
                    ]
                },
                options: {
                    title: {
                        display: true,
                        text: 'Condiciones Ambientales'
                    },
                    legend: {
                        display: true
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Tiempo Simulación'
                            }
                        }],
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Factor'
                            }
                        }],
                    }
                }
            };
            this.environmentalVariablesChart = new Chart(ctxEnviromentalVariablesGraph, chartData);
            this.environmentalVariablesChartPDF = new Chart(ctxPdfGraph, chartData);
        }else{
            const chartData = {
                type: 'line',
                data: {
                    labels: this.generateLabels(),
                    datasets: [
                        {
                            label: this.variables[6].name,
                            data: this.generateData(this.variables[6].id),
                            borderColor: '#C0C0C0',
                            fill: false
                        },
                        {
                            label: this.variables[7].name,
                            data: this.generateData(this.variables[7].id),
                            borderColor: '#808080',
                            fill: false
                        }
            
                    ]
                },
                options: {
                    title: {
                        display: true,
                        text: 'Condiciones Ambientales'
                    },
                    legend: {
                        display: true
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Tiempo Simulación'
                            }
                        }],
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Factor'
                            }
                        }],
                    }
                }
            };
            this.environmentalVariablesChart = new Chart(ctxEnviromentalVariablesGraph, chartData);
            this.environmentalVariablesChartPDF = new Chart(ctxPdfGraph, chartData);
        }
        
      
    }

    criticalVariablesPDF() {
        this.pdfService.graphPDF('criticalVariablesGraph');
    }

    impactVariablesPDF() {
        this.pdfService.graphPDF('impactVariablesGraph');
    }

    environmentalVariablesPDF() {
        this.pdfService.graphPDF('environmentalVariablesGraph');
    }

}
