import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(){
    let init = () => {
      Array.from(document.querySelectorAll(".cdk-overlay-container .cdk-overlay-pane")).forEach((e) => {
        if(e.firstElementChild.classList.contains("mat-menu-panel")){
          e.classList.add("ignore");
        }
      });
      setTimeout(init, 100);
    }
    init();
  }
}
