import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-remove-variable',
  templateUrl: './remove-variable.component.html',
  styleUrls: ['./remove-variable.component.scss']
})
export class RemoveVariableComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemoveVariableComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  deleteVariable() {
    this.dialogRef.close(true);
  }

}
