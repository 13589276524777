import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ApiService} from '../../../../../../services/api.service';

@Component({
    selector: 'app-remove-script',
    templateUrl: './remove-script.component.html',
    styleUrls: ['./remove-script.component.scss']
})
export class RemoveScriptComponent implements OnInit {
    id: number;

    constructor(public dialogRef: MatDialogRef<RemoveScriptComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public snackBar: MatSnackBar,
                private apiService: ApiService) {
        dialogRef.disableClose = true;
        this.id = data.id;
    }

    ngOnInit() {
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    deleteScript() {
        this.apiService.deleteScript(this.id).subscribe(response => {
            if (response.success) {
                this.openSnackBar(response.message, 'Aceptar');
                this.dialogRef.close(true);
            } else {
                this.openSnackBar(response.message, 'Aceptar');
            }
        }, error => {
            if (error) {
                this.openSnackBar('No se ha podido remover el guión (Error)', 'Aceptar');
            }
        });
    }

}
