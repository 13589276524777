import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    //URL_BASE = 'http://vjs.cl:5000/api/';

    //URL_BASE = 'http://164.77.114.239:5000/api/';
    URL_BASE = 'https://vjs.cl:5000/api/';
    constructor(private _router: Router,
                private _http: HttpClient) {
    }

    /**
     *
     * Peticiones de Usuarios
     */
    getUsers(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Users/', {headers});
    }

    getUser(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Users/' + id, {headers});
    }

    getDocuments(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Document/' + id + "/get", {headers});
    }

    getAlternatives(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Alternatives/Simulation/' + id, {headers});
    }

    getAlternativesFull(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Alternatives/Simulation/' + id + '/full', {headers});
    }

    getAlternative(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Alternatives/' + id, {headers});
    }

    createAlternative(alternative): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Alternatives/', alternative, {headers});
    }

    updateAlternative(alternative): Observable<any> {
        console.log(alternative);
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'Alternatives/' + alternative.id, alternative, {headers});
    }

    updateAlternativeEvaluation(alternative): Observable<any> {
        console.log(alternative);
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'Alternatives/' + alternative.id + '/evaluation', alternative, {headers});
    }

    createUser(user): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Users/', user, {headers});
    }

    updateUser(user): Observable<any> {
        console.log(user);
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'Users/' + user.id, user, {headers});
    }

    deleteUser(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'Users/' + id, {headers});
    }

    /**
     *
     * Peticiones de Roles
     */
    getRoles(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Roles/', {headers});
    }

    getRole(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Roles/' + id, {headers});
    }

    getRolebyUser(id_sim, id_user): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationRoles/bySimulation/' + id_sim + '/byUser/' + id_user, {headers});
    }


    /**
     *
     * CRUD de Simulaciones
     */
    getSimulations(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Simulations/', {headers});
    }

    getSimulationsByDisaster(disasterId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Simulations/byDisaster/' + disasterId, {headers});
    }

    getSimulationsByDisasterInProgress(disasterId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Simulations/byDisaster/' + disasterId + '/inProgress', {headers});
    }

    getSimulation(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Simulations/' + id, {headers});
    }

    createSimulation(simulation): Observable<any> {
        console.log(simulation);
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Simulations/', simulation, {headers});
    }

    updateSimulation(id, simulation): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'Simulations/' + id, simulation, {headers});
    }

    deleteSimulation(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'Simulations/' + id, {headers});
    }

    /**
     *
     * CRUD del Log de Simulaciones
     */
    getSimulationLogs(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationLogs/', {headers});
    }

    getSimulationLogsBySimulation(simulationId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationLogs/bySimulation/' + simulationId, {headers});
    }

    getSimulationLog(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationLogs/' + id, {headers});
    }

    createSimulationLog(params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'SimulationLogs/', params, {headers});
    }

    updateSimulationLog(id, params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'SimulationLogs/' + id, params, {headers});
    }

    deleteSimulationLog(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'SimulationLogs/' + id, {headers});
    }

    /**
     *
     * Peticiones de Eventos
     */
    getEvents(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Events/', {headers});
    }

    getEventsByDisaster(disasterId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Events/byDisaster/' + disasterId, {headers});
    }

    getEvent(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Events/' + id, {headers});
    }

    createEvent(params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Events/', params, {headers});
    }

    updateEvent(id, params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'Events/' + id, params, {headers});
    }

    deleteEvent(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'Events/' + id, {headers});
    }

    /**
     *
     * Peticiones de Tipos Evento
     */
    getEventTypes(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'EventTypes/', {headers});
    }

    getEventType(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'EventTypes/' + id, {headers});
    }

    createEventType(params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'EventTypes/', params, {headers});
    }

    updateEventType(id, params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'EventTypes/' + id, params, {headers});
    }

    deleteEventType(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'EventTypes/' + id, {headers});
    }

    /**
     *
     * CRUD de mandos y niveles de roles de simulación
     */
    getSimulationRoles(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationRoles/', {headers});
    }

    getSimulationRolesBySimulation(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationRoles/bySimulation/' + id, {headers});
    }

    getSimulationRoleBySimulationAndUser(id, userId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationRoles/bySimulation/' + id + '/byUser/' + userId, {headers});
    }

    getSimulationRole(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationRoles/' + id, {headers});
    }

    createSimulationRole(simulationRole): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'SimulationRoles/', simulationRole, {headers});
    }

    createSimulationRoles(simulationRoles): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'SimulationRoles/multi', simulationRoles, {headers});
    }

    updateSimulationRole(id, simulationRole): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'SimulationRoles/' + id, simulationRole, {headers});
    }

    /**
     *
     * CRUD de mandos y niveles de roles de simulación
     */
    getSimulationRoleCommands(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationRoleCommands/', {headers});
    }

    getSimulationRoleLevels(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'SimulationRoleLevels/', {headers});
    }

    /**
     *
     * Peticiones de Escenarios
     */
    getScenarios(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Scenarios/', {headers});
    }

    getScenariosByDisaster(disasterId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Scenarios/byDisaster/' + disasterId, {headers});
    }

    /**
     *
     * Peticiones de Perfiles
     */
    getProfiles(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Profiles', {headers});
    }

    getProfilesByDisaster(disasterId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Profiles/byDisaster/' + disasterId, {headers});
    }

    getProfilesByScenario(scenarioId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Profiles/byScenario/' + scenarioId, {headers});
    }

    getProfilesBySimulation(simulationId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Profiles/bySimulation/' + simulationId, {headers});
    }

    getProfile(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Profiles/' + id, {headers});
    }

    createProfile(profile): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Profiles', profile, {headers});
    }

    updateProfile(id, profile): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'Profiles/' + id, profile, {headers});
    }

    deleteProfile(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'Profiles/' + id, {headers});
    }

    /**
     *
     * CRUD de las Variables
     */
    getVariables(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Variables/', {headers});
    }

    getVariablesByDisaster(disasterId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Variables/byDisaster/' + disasterId, {headers});
    }

    getVariable(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Variables/' + id, {headers});
    }

    createVariable(params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Variables/', params, {headers});
    }

    updateVariable(id, params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'Variables/' + id, params, {headers});
    }

    deleteVariable(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'Variables/' + id, {headers});
    }

    /**
     *
     * CRUD de los Grupos de Variables
     */
    getVariableGroups(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'VariableGroups/', {headers});
    }

    getVariableGroupsByDisaster(disasterId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'VariableGroups/', {headers});
    }

    getVariableGroup(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'VariableGroups/' + id, {headers});
    }

    createVariableGroup(params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'VariableGroups/', params, {headers});
    }

    updateVariableGroup(id, params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'VariableGroups/' + id, params, {headers});
    }

    deleteVariableGroup(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'VariableGroups/' + id, {headers});
    }

    /**
     *
     * CRUD de los Guiones
     */
    getScripts(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Scripts/', {headers});
    }

    getScriptsByDisaster(disasterId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Scripts/byDisaster/' + disasterId, {headers});
    }

    getScriptsByProfile(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Scripts/byProfile/' + id, {headers});
    }

    getScript(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Scripts/' + id, {headers});
    }

    createScript(script): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Scripts/', script, {headers});
    }

    updateScript(id, script): Observable<any> {
        console.log(script);
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'Scripts/' + id, script, {headers});
    }

    deleteScript(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'Scripts/' + id, {headers});
    }

    /**
     *
     * Peticiones de los Eventos de Guión
     */
    getEventsScript(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'EventsScript/', {headers});
    }

    getEventsScriptByScript(scriptId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'EventsScript/byScript/' + scriptId, {headers});
    }

    getEventScript(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'EventsScript/' + id, {headers});
    }

    createEventsScript(eventsScript): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'EventsScript/multi', eventsScript, {headers});
    }

    createEventScript(eventScript): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'EventsScript/', eventScript, {headers});
    }

    updateEventScript(id, eventScript): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.put(this.URL_BASE + 'EventsScript/' + id, eventScript, {headers});
    }

    deleteEventScript(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.delete(this.URL_BASE + 'EventsScript/' + id, {headers});
    }


    /**
     *
     * Peticiones de Guiones
     */
    getScriptByScenario(scenarioId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Script/GetScriptByScenario/' + scenarioId, {headers});
    }

    getProfileBySimulation(simulationId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Profile/GetProfileBySimulation/' + simulationId, {headers});
    }

    getProfileByScenario(scenarioId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Profile/GetProfileByScenario/' + scenarioId, {headers});
    }

    getProfileByScript(scriptId): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Profile/GetProfileByScript/' + scriptId, {headers});
    }

    /**
     *
     * Simulaciones en proceso
     */
    getRunningSimulations(disaster): Observable<any> {
        const params = JSON.stringify({'sessionType': disaster});
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'SimAPI/GetRunningSimulations/', params, {headers});
    }

    // TODO: Falta agregar columnas faltantes al registro
    createSessionSimulation(session): Observable<any> {
        const params = JSON.stringify({
            'nameSession': session.name,
            'typeSession': session.type,
            'scriptSession': session.script,
            'profileSession': session.profile,
            'scenarioSession': session.scenario,
            'cascadeEffectSession': session.valueCascade,
            'dateSession': session.date,
        });
        // console.log(params);
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'SimAPI/CreateSessionSimulation/', params, {headers});
    }

    StartSimulation(idSession) {
        // envia id sesion
        const params = JSON.stringify({'Id': idSession});
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Simulations/StartSimulation/', params, {headers});
    }

    FinishSimulation(idSession, idSessionTmp) {
        // envia id sesion
        console.log(idSession);
        console.log(idSessionTmp);
        const params = JSON.stringify({'Id': idSession, 'TmpId':idSessionTmp});
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Simulations/EndSimulation/', params, {headers});
    }

    getGeoScenario(id): Observable<any> {
        const params = JSON.stringify({'id': id});
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'SimAPI/GetGeoScenario/', params, {headers});
    }

    // POST Profile Conditions (general + modelcells + modelenvironmentvar+modelprobvar)
    createProfileConditions(profile): Observable<any> {
        /*const params = JSON.stringify({
            'nameSession': profile.name,
            'typeSession': profile.type,
        });*/
        const params = JSON.stringify(profile);
        console.log(params);
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.post(this.URL_BASE + 'Profiles/createProfileConditions/', params, {headers});
    }

    // Port
    getPortByID(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this._http.get(this.URL_BASE + 'Simulations/Port/' + id, {headers});
    }
}
