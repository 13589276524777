import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name:"conditionsTransform"
  })

  export class ConditionsPipe implements PipeTransform{
    transform(value:string) : string{
      let condFormatt="";
      switch(value){
        
        case 'temperature':
            condFormatt="T° ";
        break;
        
        case 'humedity':
            condFormatt="H ";
        break;

        case 'wind_speed':
            condFormatt="WS ";
        break;

        case 'wind_direction':
            condFormatt="WD ";
        break;

        case 'pressure':
            condFormatt="P ";
        break;

      }
      
      return condFormatt;
    }
  }
