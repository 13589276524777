import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {Variable} from '../../../../models/Variable';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {DisasterPipe} from '../../../../pipes/disaster.pipe';
import {NewEventComponent} from '../events/dialogs/new-event/new-event.component';
import {EditEventComponent} from '../events/dialogs/edit-event/edit-event.component';
import {RemoveEventComponent} from '../events/dialogs/remove-event/remove-event.component';
import {NewVariableComponent} from './dialogs/new-variable/new-variable.component';
import {EditVariableComponent} from './dialogs/edit-variable/edit-variable.component';
import {RemoveVariableComponent} from './dialogs/remove-variable/remove-variable.component';

@Component({
    selector: 'app-variables',
    templateUrl: './variables.component.html',
    styleUrls: ['./variables.component.scss']
})
export class VariablesComponent implements OnInit {
    title = '';
    disaster;
    disasterId;
    filter = '';
    displayedColumns = ['row', 'name', 'value', 'weight', 'variableGroupName', 'action'];
    dataSource;
    variables: Variable[] = [];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private apiService: ApiService,
                private snackBar: MatSnackBar,
                private disasterPipe: DisasterPipe) {
        this.title = this.route.snapshot.data.title;
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.disaster, 'id');
    }

    ngOnInit() {
        this.apiService.getVariablesByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.variables = response.data;
                this.dataSource = new MatTableDataSource<Variable>(this.variables);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    newVariable() {
        const dialogRef = this.dialog.open(NewVariableComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                disasterId: this.disasterId
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.apiService.createVariable(result.data).subscribe(response => {
                    if (response.success) {
                        this.openSnackBar(response.message, 'Aceptar');
                        this.ngOnInit();
                    } else {
                        this.openSnackBar(response.message, 'Aceptar');
                    }
                }, error => {
                    if (error) {
                        this.openSnackBar('No se ha podido agregar la variable', 'Aceptar');
                    }
                });
            }
        });
    }

    editVariable(id, variable) {
        const dialogRef = this.dialog.open(EditVariableComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                disasterId: this.disasterId,
                id: id,
                variable: variable
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.apiService.updateVariable(id, result.data).subscribe(response => {
                    if (response.success) {
                        this.openSnackBar(response.message, 'Aceptar');
                        this.ngOnInit();
                    } else {
                        this.openSnackBar(response.message, 'Aceptar');
                    }
                }, error => {
                    if (error) {
                        this.openSnackBar('No se ha podido actualizar la variable', 'Aceptar');
                    }
                });
            }
        });
    }

    removeVariable(id) {
        const dialogRef = this.dialog.open(RemoveVariableComponent, {
            maxWidth: '650px',
            width: '98%',
            autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.apiService.deleteVariable(id).subscribe(response => {
                    if (response.success) {
                        this.openSnackBar(response.message, 'Aceptar');
                        this.ngOnInit();
                    } else {
                        this.openSnackBar(response.message, 'Aceptar');
                    }
                }, error => {
                    if (error) {
                        this.openSnackBar('No se ha podido remover la variable', 'Aceptar');
                    }
                });
            }
        });
    }
}
