import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-remove-alternative-event-script',
  templateUrl: './remove-alternative-event-script.component.html',
  styleUrls: ['./remove-alternative-event-script.component.scss']
})
export class RemoveAlternativeEventScriptComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<RemoveAlternativeEventScriptComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
    }

    deleteAlternativeEventScript() {
        this.dialogRef.close(true);
    }

}
