import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ApiService} from '../../../../../services/api.service';
import {AuthService} from '../../../../../services/auth.service';
import {DisasterPipe} from '../../../../../pipes/disaster.pipe';
import {Scenario} from '../../../../../models/Scenario';
import {DisasterCell, DisasterCellLine} from '../../../../../models/Disaster';
import {ConditionEnvironment} from '../../../../../models/ConditionEnvironment';
import {ConditionProb} from '../../../../../models/ConditionProb';
import {Profile} from '../../../../../models/Profile';

@Component({
    selector: 'app-new-profile',
    templateUrl: './new-profile.component.html',
    styleUrls: ['./new-profile.component.scss']
})
export class NewProfileComponent implements OnInit {

    profileFormGroup: FormGroup;

    disaster;
    disasterId;
    scenarios: Scenario[] = [];

    disasterCells: DisasterCell[] = [];
    dcph: DisasterCellLine[] = [];
    dcpv: DisasterCellLine[] = [];

    modelProb: ConditionProb[] = [];
    modelEnv: ConditionEnvironment[] = [];

    colors = ['white', 'yellow', 'red', 'black', 'brown'];

    lat = -35.4519;
    lng = -72.2773;
    latUL = -35.3803;
    lngUL = -72.3652;
    latBR = -35.5235;
    lngBR = -72.1894;
    n_size = 9;
    drawLines = false;

    profile: Profile = {
        size: 0,
        name: '',
        scenarioId: 0,
        description: '',
        cells: [],
        environments: [],
        probabilistics: []
    };

    constructor(private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private apiService: ApiService,
                private authService: AuthService,
                private disasterPipe: DisasterPipe,
                private formBuilder: FormBuilder) {
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.disaster, 'id');

        this.profileFormGroup = formBuilder.group({
            nameCtrl: ['', Validators.required],
            scenarioCtrl: ['', Validators.required],
            descriptionCtrl: ['', Validators.required],
            sizeCtrl: ['', Validators.required],
            profileTCtrl: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(100)])],
            profileHCtrl: ['', Validators.required],
            profileWSCtrl: ['', Validators.required],
            profileWACtrl: ['', Validators.required],
            profilePCtrl: ['', Validators.required],
            profileP23Ctrl: ['', Validators.required],
            profileP24Ctrl: ['', Validators.required],
            profileP42Ctrl: ['', Validators.required]

        });
    }

    ngOnInit() {
        if (this.disasterId === 1) {
            this.modelEnv = [
                {nameEnvironment: 'temperature', valueEnvironment: 40},
                {nameEnvironment: 'humedity', valueEnvironment: 30},
                {nameEnvironment: 'wind_speed', valueEnvironment: 30},
                {nameEnvironment: 'wind_direction', valueEnvironment: 30},
                {nameEnvironment: 'pressure', valueEnvironment: 30}
            ];
            this.modelProb = [
                {nameProb: 'f12', valueProb: 0.1},
                {nameProb: 'f23', valueProb: 0.1},
                {nameProb: 'f24', valueProb: 0.1},
                {nameProb: 'f42', valueProb: 0.1}
            ];
            this.profileFormGroup.patchValue({
                profileTCtrl: this.modelEnv[0].valueEnvironment,
                profileHCtrl: this.modelEnv[1].valueEnvironment,
                profileWSCtrl: this.modelEnv[2].valueEnvironment,
                profileWACtrl: this.modelEnv[3].valueEnvironment,
                profilePCtrl: this.modelEnv[4].valueEnvironment,
                profileP23Ctrl: this.modelProb[0].valueProb,
                profileP24Ctrl: this.modelProb[1].valueProb,
                profileP42Ctrl: this.modelProb[2].valueProb,
                sizeCtrl: this.n_size
            });
        }

        this.apiService.getScenariosByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.scenarios = response.data;
            }
        });
        this.apiService.getScenariosByDisaster(this.disasterId).subscribe((response) => {
            const scenario = response.data;
            this.lat = scenario.latitude;
            this.lng = scenario.longitude;
            /* this._map.lat=geoScenario.data[0].latitude;
             this. _map.lng=geoScenario.data[0].longitude;*/
            this.createGrid();
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    selectScenario(scenarioId) {
        const currentScenario = this.scenarios.find(scenario => scenario.id === scenarioId);
        this.lat = currentScenario.latitude;
        this.lng = currentScenario.longitude;
        this.latUL = currentScenario.initialLatitude;
        this.lngUL = currentScenario.initialLongitude;
        this.latBR = currentScenario.finalLatitude;
        this.lngBR = currentScenario.finalLongitude;
    }

    onSearchChange(searchValue: number) {
        this.n_size = searchValue;
        this.createGrid();
    }

    createGrid() {
        this.dcph = [];
        this.dcpv = [];


        const dLat = (this.latBR - this.latUL) / this.n_size;
        const dLng = (this.lngBR - this.lngUL) / this.n_size;

        for (let i = 0; i < this.n_size + 1; i++) {
            // Horizontal points
            this.dcph.push({
                id: 'fcph' + i,
                latA: this.latUL + dLat * i,
                lngA: this.lngUL,
                latB: this.latUL + dLat * i,
                lngB: this.lngBR
            });

            // Vertical points
            this.dcpv.push({
                id: 'fcpv' + i,
                latA: this.latUL,
                lngA: this.lngUL + dLng * i,
                latB: this.latBR,
                lngB: this.lngUL + dLng * i,
            });
        }
    }

    mapClicked($event: any) {
        // Out of map
        if (!this.checkBorders($event.coords.lat, $event.coords.lng)) {
            return;
        }

        const rc = this.getRowCol($event.coords.lat, $event.coords.lng);
        const id = this.getID(rc[0], rc[1]);
        const fc = this.searchDisasterCellsById(id);

        if (fc != null) {
            fc.state = this.checkState(fc.state);
        } else {
            this.disasterCells.push({
                id: id,
                path: this.cellPathID(id),
                state: 1
            });
        }

        this.clearCells(0); // Remove cells with state 0
    }

    mapZoom($event: any) {

        if (this.n_size !== undefined) {
            if (this.n_size <= 50) {
                this.drawLines = $event > 8;
            } else if (this.n_size <= 100) {
                this.drawLines = $event > 13;
            } else if (this.n_size <= 750) {
                this.drawLines = $event > 15;
            } else {
                this.drawLines = $event > 17;
            }
        }
    }


    checkBorders(lat, lng) {
        return lat <= this.latUL && lat >= this.latBR && lng >= this.lngUL && lng <= this.lngBR;
    }

    getRowCol(lat, lng) {
        const tc = this.cellSize();
        const metersLatLng = this.deltaLatLngToMeters(lat, lng);
        const i = Math.floor(metersLatLng[1] / tc[1]);
        const j = Math.floor(metersLatLng[0] / tc[0]);
        return [i, j];
    }

    getID(i, j) {
        return i * this.n_size + j;
    }

    searchDisasterCellsById(id) {
        let fc = null;
        for (let i = 0; i < this.disasterCells.length; i++) {
            if (this.disasterCells[i].id === id) {
                fc = this.disasterCells[i];
                break;
            }
        }
        return fc;
    }

    checkState(state) {
        let rs = 0;
        if (state === 0) {
            rs = 1;
        } else if (state === 1) {
            rs = 2;
        }
        return rs;
    }

    cellPathID(id) {
        const ij = this.getIJ(id);
        const borders = this.cellBorders(ij[0], ij[1]);
        return [
            {lat: borders[0], lng: borders[1]},
            {lat: borders[0], lng: borders[3]},
            {lat: borders[2], lng: borders[3]},
            {lat: borders[2], lng: borders[1]},
            {lat: borders[0], lng: borders[1]}
        ];
    }

    clear() {
        this.disaster = [];
        this.dcph = [];
        this.dcpv = [];
        this.drawLines = false;

    }

    cellSize() {

        const metersLatLng = this.deltaLatLngToMeters(this.latBR, this.lngBR);
        return [metersLatLng[0] / this.n_size, metersLatLng[1] / this.n_size];
    }

    clearCells(state) {
        this.disasterCells = this.disasterCells.filter(function (e) {
            return e.state !== state;
        });
    }

    deltaLatLngToMeters(lat, lng) {
        const deltaLatitude = lat - this.latUL;
        const deltaLongitude = lng - this.lngUL;
        const latitudeCircumference = 40075160 * Math.cos(this.asRadians(this.latUL));
        const resultX = Math.abs(deltaLongitude * latitudeCircumference / 360);
        const resultY = Math.abs(deltaLatitude * 40008000 / 360);
        return [resultX, resultY];
    }

    getIJ(id) {
        const i = Math.floor(parseFloat(id) / this.n_size);
        const j = Number(parseFloat(id) % this.n_size);
        return [i, j];
    }

    cellBorders(i, j) {
        const tc = this.cellSizeLatLng();
        const latA = this.latUL + tc[0] * i;
        const lngA = this.lngUL + tc[1] * j;
        const latB = this.latUL + tc[0] * (i + 1);
        const lngB = this.lngUL + tc[1] * (j + 1);
        return [latA, lngA, latB, lngB];
    }

    asRadians(degrees: number) {
        return degrees * Math.PI / 180;
    }

    cellSizeLatLng() {

        const dLat = (this.latBR - this.latUL) / this.n_size;
        const dLng = (this.lngBR - this.lngUL) / this.n_size;
        return [dLat, dLng];
    }

    mapRightClicked($event: MouseEvent) {
    }

    createProfile() {
        /*
        const dataCells = JSON.parse(JSON.stringify(this.disasterCells));
        dataCells.forEach(function (v) {
            delete v.path;
        });
        */

        if (this.profileFormGroup.valid) {
            this.modelEnv = [
                {nameEnvironment: 'temperature', valueEnvironment: this.profileFormGroup.get('profileTCtrl').value},
                {nameEnvironment: 'humedity', valueEnvironment: this.profileFormGroup.get('profileHCtrl').value},
                {nameEnvironment: 'wind_speed', valueEnvironment: this.profileFormGroup.get('profileWSCtrl').value},
                {nameEnvironment: 'wind_direction', valueEnvironment: this.profileFormGroup.get('profileWACtrl').value},
                {nameEnvironment: 'pressure', valueEnvironment: this.profileFormGroup.get('profilePCtrl').value}
            ];
            this.modelProb = [
                {nameProb: 'f12', valueProb: 0.1},
                {nameProb: 'f23', valueProb: this.profileFormGroup.get('profileP23Ctrl').value},
                {nameProb: 'f24', valueProb: this.profileFormGroup.get('profileP24Ctrl').value},
                {nameProb: 'f42', valueProb: this.profileFormGroup.get('profileP42Ctrl').value}
            ];
            const profile = {
                name: this.profileFormGroup.get('nameCtrl').value,
                description: this.profileFormGroup.get('descriptionCtrl').value,
                scenarioId: this.profileFormGroup.get('scenarioCtrl').value,
                size: this.profileFormGroup.get('sizeCtrl').value,
                cells: JSON.stringify(this.disasterCells),
                environments: JSON.stringify(this.modelEnv),
                probabilistics: JSON.stringify(this.modelProb)
            };
            this.apiService.createProfile(profile).subscribe(response => {
                if (response.success) {
                    this.openSnackBar('El Perfil ha sido creado con éxito', 'Aceptar');
                    this.router.navigate(['../'], {relativeTo: this.route}).then();
                } else {
                    this.openSnackBar('No se ha podido crear el perfil', 'Aceptar');
                }
            }, error => {
                console.log(error);
                this.openSnackBar('No se ha podido crear el perfil', 'Aceptar');
            });
        } else {
            this.openSnackBar('Falta completar campos requeridos', 'Aceptar');
        }
    }
}
