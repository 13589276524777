import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DisasterPipe} from "../../../../pipes/disaster.pipe";
import {Simulation} from "../../../../models/Simulation";

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
    title = '';
    filter = '';
    disaster;
    disasterId;
    displayedColumns = ['row', 'name', 'scriptName', 'startDate', 'action'];
    dataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    results: Simulation[] = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private apiService: ApiService,
                private disasterPipe: DisasterPipe) {
        this.title = this.route.snapshot.data.title;
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.disaster, 'id');
    }

    ngOnInit() {
        this.apiService.getSimulationsByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.results = response.data;
                this.dataSource = new MatTableDataSource<Simulation>(this.results);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }, error => {
            if (error) {
            }
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

}
