import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-remove-profile',
  templateUrl: './remove-profile.component.html',
  styleUrls: ['./remove-profile.component.scss']
})
export class RemoveProfileComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemoveProfileComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  deleteProfile() {
    this.dialogRef.close(true);
  }

}
