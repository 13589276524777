import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ApiService} from '../../../../services/api.service';
import {DisasterPipe} from '../../../../pipes/disaster.pipe';
import {Script} from '../../../../models/Script';
import {RemoveScriptComponent} from './dialogs/remove-script/remove-script.component';

@Component({
    selector: 'app-scripts',
    templateUrl: './scripts.component.html',
    styleUrls: ['./scripts.component.scss']
})
export class ScriptsComponent implements OnInit {
    title = '';
    disaster;
    disasterId;
    filter = '';
    displayedColumns = ['row', 'name', 'time', 'profileName', 'action'];
    dataSource;
    scripts: Script[] = [];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;


    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private apiService: ApiService,
                private disasterPipe: DisasterPipe) {
        this.title = this.route.snapshot.data.title;
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.disaster, 'id');
    }

    ngOnInit() {
        this.apiService.getScriptsByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.scripts = response.data;
                this.dataSource = new MatTableDataSource<Script>(this.scripts);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }, error => {
            if (error) {
            }
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    deleteScript(id) {
        const dialogRef = this.dialog.open(RemoveScriptComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {id: id}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

}
