import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material';
import {MediaMatcher} from '@angular/cdk/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {DisasterPipe} from '../../pipes/disaster.pipe';

@Component({
    selector: 'app-platform',
    templateUrl: './platform.component.html',
    styleUrls: ['./platform.component.scss']
})
export class PlatformComponent implements OnInit, OnDestroy {

    @ViewChild('sidenav') sidenav: MatSidenav;
    mobileQuery: MediaQueryList;
    mobileQueryListener: () => void;
    currentRoute;
    dataRoute;
    disasterType;
    title = '';
    simulation = false;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private media: MediaMatcher,
                private route: ActivatedRoute,
                private router: Router,
                private disasterPipe: DisasterPipe) {
        this.mobileQuery = media.matchMedia('(max-width: 768px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this.mobileQueryListener);
        this.router.events
            .subscribe(() => {
                this.simulation = false;
                this.sidenav.open().then();
                this.currentRoute = this.router.url;
                //console.log(this.currentRoute);
                this.disasterType = null;
                this.title = '';
                if (this.route.snapshot.data) {
                    this.dataRoute = this.route.snapshot.data;
                    if (this.dataRoute.title) {
                        this.title = this.dataRoute.title;
                    }
                }
                if (this.route.snapshot.firstChild.data) {
                    this.dataRoute = this.route.snapshot.firstChild.data;
                    if (this.dataRoute.title) {
                        this.title = this.title + ' ' + this.dataRoute.title;
                    }
                }
                if (this.router.url !== '/platform/home') {
                    if (this.route.snapshot.firstChild.firstChild.data) {
                        this.dataRoute = this.route.snapshot.firstChild.firstChild.data;
                        if (this.dataRoute.title) {
                            this.title = this.title + ' - ' + this.dataRoute.title;
                        }
                    }
                    if (this.route.snapshot.firstChild.params.type) {
                        this.simulation = false;
                        this.sidenav.open().then();
                        this.disasterType = this.route.snapshot.firstChild.params.type;
                        this.title = disasterPipe.transform(this.route.snapshot.firstChild.params.type) + this.title;
                        if (this.route.snapshot.firstChild.firstChild.firstChild.data) {
                            this.dataRoute = this.route.snapshot.firstChild.firstChild.firstChild.data;
                            if (this.dataRoute.title) {
                                this.title = this.title + ' - ' + this.dataRoute.title;
                            }
                            if (this.dataRoute.title === 'Simulación') {
                                this.simulation = true;
                                this.sidenav.close().then();
                            }
                        } else {
                            this.sidenav.open().then();
                            this.simulation = false;
                        }
                    }
                }
            });
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this.mobileQueryListener);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.mobileQuery.matches && !this.simulation) {
            this.sidenav.close().then();
        }
        if (!this.mobileQuery.matches && !this.simulation) {
            this.sidenav.open().then();
        }
    }
}
