import {Component, ViewChild, ViewContainerRef, HostListener, ElementRef, ComponentFactoryResolver, Inject, OnInit, Input, AfterViewInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatSnackBar} from '@angular/material';

import { UploadService } from  '../../../../../services/upload.service';

import {WebsocketService} from '../../../../../services/websocket.service';
import {ApiService} from '../../../../../services/api.service';
import {AuthService} from '../../../../../services/auth.service';
import {DisasterCell} from '../../../../../models/Disaster';
import {ConditionEnvironment} from '../../../../../models/ConditionEnvironment';
import { ChatComponent } from './chat/chat.component';
import {AlternativesSimulationComponent} from './dialogs/alternatives-simulation/alternatives-simulation.component';

import {DisasterPipe} from "../../../../../pipes/disaster.pipe";
import { profileWildfire } from "../../../../../models/Wildfire";
import { profileFlood } from "../../../../../models/Flood";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Overlay } from '@angular/cdk/overlay';

import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material';

/*
import { ZoomMtg } from '@zoomus/websdk';
import * as jQuery from 'jquery';


ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
*/
interface Marker {
    lat: number;
    lng: number;
    id: number;
    draggable: boolean;
}

interface disasterCell {
    id: number;
    path: any;
    state: number;
  }

@Component({
    selector: 'app-simulation',
    templateUrl: './simulation.component.html',
    styleUrls: ['./simulation.component.scss',
    '../../../../../../../../../../var/www/html/unity/SO/TemplateData/style.css'],
    providers: [WebsocketService]
})
export class SimulationComponent implements OnInit, AfterViewInit {

   /* lat = -39.416;
    lng = -72.231;*/
    zoom = 15;
    players: Marker[] = [];
    map: any;
    simulationOn = false;
    system_messages: string[] = [];
    simuId;
    pathUnity: string;
   
    simulationRole2;

    public simulationRole;
    public simulationRoleCommandName;
   public  simulationRoleLevelName;

    private identity;
    private type;
    private idSimulation;
    public idSimulationTmp;
    private webSocket;
    private file = {name: ""};
    private updateAnswersInstance;

    timerInfo: string;
    timerCount: number;
    hideInitButton: boolean = false;

    body = <HTMLDivElement> document.body;
    
    videoList = {
        embed: null,
        time: 0,
        play: false,
        autoPlay: false
    };

    videoList2 = {
        embed: null,
        time: 0,
        play: false,
        autoPlay: false
    };

    answers = [];

    private lat;
	private lng;
    private latUL;
	private lngUL;
	private latBR;
    private lngBR;

    private colors;
    private _profile;
/*latUL = -35.3803;
    lngUL = -72.3652;
    latBR = -35.5235;
    lngBR = -72.1894;*/
    n_size: number;
    disasterCells: DisasterCell[] = [];
    simStart = false;
    simStop = false;

    showInitialVideo = false;
    
    //public colors: string[] = ['white', 'yellow', 'red', 'black', 'brown'];
    cell: number[] = [];

    files = {};

    fileList = [];
    fileListURL = [];
    //public URLPATH = 'C:/Users/cnavarrom/Documents/GitHub/SIM_CORE/wwwroot/Upload/';
    public URLPATH = 'C:/files/';
    modelEnv: ConditionEnvironment[];
    modelEnvChange: ConditionEnvironment[];
    newEnvData = ['', '', '', '', ''];

    chatReference: any;
    disasterId;
    modo_3d = "Ambiente 3D";
    containerinfo2 = false;
    container3D = true;

    @ViewChild('appChat', { read: ViewContainerRef }) componentHolder: ViewContainerRef;
    @ViewChild('iframe') iframe: ElementRef;
    
   
   /* signatureEndpoint = 'http://vjs.cl:4000'
    apiKey = '4ipl-tz_SAGzlXJGq3VeZg'
    meetingNumber = 98265732987
    role = 0
    leaveUrl = 'https://localhost:4200'
    userName = 'Angular'
    userEmail = ''
    passWord = '406963'
*/
    constructor(private _route: ActivatedRoute,
                private _router: Router,
                private _apiService: ApiService,
                private _uploadService: UploadService,
                private _auth: AuthService,
                private _sanitizer: DomSanitizer,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private disasterPipe: DisasterPipe,
                private overlay: Overlay,
                private componentFactoryResolver: ComponentFactoryResolver,
                @Inject('Window') window: Window,
                public httpClient: HttpClient 
                /*@Inject(DOCUMENT) document*/) {
       
                   
        this.idSimulation = _route.snapshot.params.id;
        this.type = this._route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.type, 'id');

        this.videoList2.embed = this.getCodeFromUrl('https://www.youtube.com/embed/jKIZEfSzmIY');
       
        this.webSocket = new WebsocketService();
       // this.simulationRole = _apiService.getPlayerRole();
       
       this.resetValues(); 
      // this.simulationidTMP();
        this._apiService.getProfilesBySimulation(this.idSimulation).subscribe(response => { 
            this.n_size=response.data[0].size;
        
        }, error => {
            console.log(error);
        });
       
       this._apiService.getSimulation(this.idSimulation).subscribe(response => { 
        
                    console.log("simu",response);
                    if (response.success) {
                    
                       this.idSimulationTmp = response.data.tmpId;
                       this._apiService.getPortByID(this.idSimulationTmp).subscribe(res => {
                        console.log("resrdata",res.data);
                        this.webSocket.connectSocket(res.data.port);
                        this.webSocket.connectSocketBroadcasting(res.data.broadcasting);
                    });
                    }
        
                }, error => {
                    console.log(error);
                });
       
      
        
        this._auth.getUser().subscribe(response => {
            console.log("getUSer",response);
            if (response.success) {
                console.log(this.idSimulation + '+ '+ response.data.id);
                this.simulationRole2= this._apiService.getRolebyUser(this.idSimulation, response.data.id);
              this._apiService.getRolebyUser(this.idSimulation, response.data.id).subscribe(response2 => {
                console.log("getRoleUser",response2);
                if (response2.success) {
                    console.log("role",response2.data);
                    this.simulationRole = response2.data;
                    if(this.simulationRole.simulationRoleCommandId == 1){this.simulationRoleCommandName = 'Tecnico';}
                    if(this.simulationRole.simulationRoleCommandId == 2){this.simulationRoleCommandName = 'Coordinador';}
                    if(this.simulationRole.simulationRoleCommandId == 3){this.simulationRoleCommandName = 'Autoridad';}
                    if(this.simulationRole.simulationRoleLevelId == 1){this.simulationRoleLevelName = 'Comunal';}
                    if(this.simulationRole.simulationRoleLevelId == 2){this.simulationRoleLevelName = 'Regional';}
                    if(this.simulationRole.simulationRoleLevelId == 3){this.simulationRoleLevelName = 'Nacional';}
                    console.log("aaaa"+this.simulationRoleCommandName);
                    console.log("bbbb"+this.simulationRoleLevelName);
                    
                    this.identity = "Moderador";
                }
            }, error => {
                console.log(error);
            });
            }
        }, error => {
            console.log(error);
        });

        
        this.webSocket.getValues2 = b =>{
            //console.log("ByTE WS");
            var scope = this;
            var fr = new FileReader();
            fr.onload = function() {
                var byteStates = new Int8Array(fr.result);
              
                if(byteStates.length==0){
                  byteStates[0]=0;
                  byteStates[1]=0;
               }
               
               //console.log("ByTE STATE : "+byteStates);
               scope.render(byteStates);
          
            };
            fr.readAsArrayBuffer(b);

        };
        
        this.webSocket.getValues = data => {
            if (data.type === 500) {
                console.log("ETUPE: ",data.eventType);
                switch(data.eventType){
                    case 1:
    
                       console.log("CASO 1");
                               
                    break;
                    case 2:
                       
                        console.log("CASO 2");
                      
                    break;

                    case 3:
                    let autoplay = true;
                    console.log("aaaa2"+this.simulationRoleCommandName);
                    console.log("bbbb2"+this.simulationRoleLevelName);
                    console.log("2",this.simulationRole2);
                   
                        var eventArgs = JSON.parse(data.eventArgs);
                        if (this.isDefined(eventArgs.directTo[0].levels[0])) {
                        //level Tecnico
                       
                            if (this.simulationRoleCommandName == eventArgs.directTo[0].name) {
                                if (eventArgs.directTo[0].levels[0].name == this.simulationRoleLevelName || eventArgs.directTo[0].levels[1].name == this.simulationRoleLevelName || eventArgs.directTo[0].levels[2].name == this.simulationRoleLevelName) {
                                    this.openAlternativesDialog(data, eventArgs.alternatives, eventArgs);  autoplay = false;                               }   
                            }
                        }
                        else if (this.isDefined(eventArgs.directTo[1].levels[0])) {
                        //level Coordinador
                            if (this.simulationRoleCommandName == eventArgs.directTo[1].name) {
                                if (eventArgs.directTo[1].levels[0].name == this.simulationRoleLevelName || eventArgs.directTo[1].levels[1].name == this.simulationRoleLevelName || eventArgs.directTo[1].levels[2].name == this.simulationRoleLevelName) {
                                    this.openAlternativesDialog(data, eventArgs.alternatives, eventArgs);  autoplay = false;                               }    
                            }
                        }
                        else if (this.isDefined(eventArgs.directTo[2].levels[0])) {
                        //level Autoridad
                            if (this.simulationRoleCommandName == eventArgs.directTo[2].name) {
                             if (eventArgs.directTo[2].levels[0].name == this.simulationRoleLevelName || eventArgs.directTo[2].levels[1].name == this.simulationRoleLevelName || eventArgs.directTo[2].levels[2].name == this.simulationRoleLevelName) {
                                this.openAlternativesDialog(data, eventArgs.alternatives, eventArgs);  autoplay = false;                           }
                        }
                        console.log(eventArgs.directTo[0].name);
                    }

                    if(eventArgs.yurl!=""){
                        if(autoplay){
                            this.videoList.autoPlay = true;
                        }else{
                            this.videoList.play = true;
                        }
                        this.videoList.embed = this.getCodeFromUrl(eventArgs.yurl);
                        
                        }
                break;
                }
                return;
            }else if(data.type == 501){
                console.log("501");
                clearTimeout(this.updateAnswersInstance);
                this.updateAnswersInstance = setTimeout(() => {
                    this.updateAnswers();
                }, 3000);
            }
        };


        
        switch (this.type) {
            case 'fire':
                this.pathUnity = 'SO2910';
                break;
            case 'flood':
                this.pathUnity = 'TA';
                break;
            case 'earthquake':
                this.pathUnity = 'RM';
                break;
        }

        this.runUnityLoader();
        this.runUnityProgress();
    }

   /* getSignature() {
        this.httpClient.post(this.signatureEndpoint, {
            meetingNumber: this.meetingNumber,
            role: this.role
        }).toPromise().then((data: any) => {
          if(data.signature) {
            console.log(data.signature)
            this.startMeeting(data.signature)
          } else {
            console.log(data)
          }
        }).catch((error) => {
          console.log(error)
        })
      }*/

      /*startMeeting(signature) {
       
            document.getElementById('zmmtg-root').style.display = 'block';
          
           
            ZoomMtg.init({
              leaveUrl: this.leaveUrl,
              isSupportAV: true,
              
              success: (success) => {
                console.log(success)
        
                ZoomMtg.join({
                  signature: signature,
                  meetingNumber: this.meetingNumber,
                  userName: this.userName,
                  apiKey: this.apiKey,
                  userEmail: this.userEmail,
                  passWord: this.passWord,
                  success: (success) => {
                    console.log(success)
                  },
                  error: (error) => {
                    console.log(error)
                  }
                })
        
              },
              error: (error) => {
                console.log(error)
              }
            })
          }*/

    public createComponent(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ChatComponent);
        this.chatReference = this.componentHolder.createComponent(componentFactory);
        this.updateAnswers();
        this.updateDocuments();
    }

    alternativeVisible(a){
       //console.log(a);
       //console.log(this.simulationRole);
      
        if(a.eventValue && a.eventValue.evaluatedBy){
            /*if(this.simulationRole && a.eventValue.evaluatedBy.id ==  this.simulationRole.simulationRoleLevelId &&
                a.eventValue.evaluatedBy.item && a.eventValue.evaluatedBy.item.id == this.simulationRole.simulationRoleCommandId){
                    return true;
            }*/
            if(a.eventValue.evaluatedBy.id == this.simulationRole.simulationRoleLevelId && a.eventValue.evaluatedBy.item.id == this.simulationRole.simulationRoleCommandId){
                    return true;
            }
        }
        return false;
    }

    updateAnswers(){
        console.log("update answers");
        this._apiService.getAlternatives(this.idSimulation).subscribe(response => {
            console.log(response);
            if (response.success) {
                this.answers = response.data;
                console.log(this.answers);
                for(let a of this.answers){
                    if(a.eventValue){
                        a.eventValue = JSON.parse(a.eventValue);
                    }
                }
            }
        });
    }

    updateDocuments(){
        console.log("update documents");
        this._apiService.getDocuments(this.idSimulation).subscribe(response => {
            if (response.success) {
                this.fileList = response.data;
            }
        });
    }

    ngOnDestroy() {
        //this.chatReference.destroy(); 
        this.webSocket.closews();
        if(window['magic']){
            window['magic'] = ! window['magic']; 
        }
        
       }

    resetValues(){
        //this._profile = new profileWildfire().getData();
        //this.disasterID = 2;

        this._apiService.getScenariosByDisaster(this.disasterId).subscribe(response => {
            
            if (response.success) {
                let scenarios=response.data[0];
                console.log(scenarios);
                this.lat = scenarios.latitude;
                this.lng = scenarios.longitude;
                this.latUL = scenarios.initialLatitude;
                this.lngUL = scenarios.initialLongitude;
                this.latBR = scenarios.finalLatitude; 
                this.lngBR = scenarios.finalLongitude;
                
            }
        }, error => {
            if (error) {
            }
        });

        switch (this.type) {
            case 'fire':
                this._profile = new profileWildfire().getData();
                break;
            case 'flood':
                this._profile = new profileFlood().getData();
                break;
           
        }
        this.colors = this._profile.data.colors;
        //console.log(this.colors);
					
    }

    ngAfterViewInit() {
        setTimeout(this.setResizeObserver, 3000);
    }

    /*setResizeObserver(){
        var rzcs = document.querySelectorAll(".resizable-comp:not(.observed)");
        console.log(rzcs);
        let observer = new MutationObserver(function(mutations) {
            //console.log('mutations:', mutations); 
            for(var i=0;i<mutations.length;i++){
                var t: any = mutations[i].target;
                if(t.firstElementChild && t.firstElementChild.nodeName == "CANVAS"){
                    t.firstElementChild.width = t.clientWidth;
                    t.firstElementChild.height = t.clientHeight -32;
                }
            }
        });

        for(var i=0;i<rzcs.length;i++){
            let child = rzcs[i];
            child.classList.add("observed");
            observer.observe(child, { attributes: true });
        }
    }*/

    setResizeObserver(){
        // var rzcs = document.querySelectorAll(".resizable-comp:not(.observed)");
        // console.log(rzcs);
        // let observer = new MutationObserver(function(mutations) {
        //     //console.log('mutations:', mutations); 
        //     for(var i=0;i<mutations.length;i++){
        //         var t :any = mutations[i].target;
        //         if(t.firstElementChild && t.firstElementChild.nodeName == "CANVAS"){
        //             t.firstElementChild.width = t.clientWidth;
        //             t.firstElementChild.height = t.clientHeight -32;
        //         }
        //     }
        // });

        // for(var i=0;i<rzcs.length;i++){
        //     let child = rzcs[i];
        //     child.classList.add("observed");
        //     observer.observe(child, { attributes: true });
        // }

        var tmp_rzcs = document.querySelectorAll(".resizer-comp:not(.observed)");
        var rzcs = [];
        for(var i=0;i<tmp_rzcs.length;i++){
            rzcs.push(tmp_rzcs[i]);
        }
        // console.log(rzcs);
        rzcs.forEach(function(child :any){
            child.classList.add("observed");
            var resizer = document.createElement('div');
            resizer.classList.add('resizer-dot');
            child.appendChild(resizer);

            var startX, startY, startWidth, startHeight;
            var heightDelta = 0;

            var initDrag = function(e) {
                startX = e.clientX;
                startY = e.clientY;
                //let child = e.target.parentElement;
                //startWidth = parseInt(document.defaultView.getComputedStyle(p).width, 10);
                //startHeight = parseInt(document.defaultView.getComputedStyle(p).height, 10);
                startWidth = parseInt(document.defaultView.getComputedStyle(child).width, 10);
                startHeight = parseInt(document.defaultView.getComputedStyle(child).height, 10);
                document.documentElement.addEventListener('mousemove', doDrag, false);
                document.documentElement.addEventListener('mouseup', stopDrag, false);

                //console.log(child.firstElementChild, child.firstElementChild.nodeName);
                var map = child.firstElementChild;
                if(map.nodeName == "AGM-MAP"){
                    heightDelta = startHeight - parseInt(document.defaultView.getComputedStyle(map).height, 10);
                }
            }

            var doDrag = function(e) {
                //p.style.width = (startWidth + e.clientX - startX) + 'px';
                //p.style.height = (startHeight + e.clientY - startY) + 'px';
                //let child = e.target.parentElement;
                let w = (startWidth + e.clientX - startX);
                let h = (startHeight + e.clientY - startY);
                child.style.width = w + 'px';
                child.style.height = h + 'px';
                child.style.maxWidth = w + 'px';
                child.style.maxHeight = h + 'px';
                
                if(child.children.length > 1 && child.children[1].id == "gameContainer" && child.children[1].firstElementChild){
                    //var canvas = child.firstElementChild.firstElementChild;
                    var canvas = child.children[1].firstElementChild;
                    //console.log(canvas)
                    if(canvas){
                        if(canvas.nodeName == "CANVAS"){
                            canvas.width = w - 50;
                            canvas.height = h - 150;
                            console.log(canvas.width, canvas.height);
                        }else{
                            if(canvas.children.length > 1 && canvas.children[1].firstElementChild){
                                // var iframe = canvas.firstElementChild.firstElementChild;
                                // if(iframe.nodeName == "IFRAME"){
                                //     iframe.height = h;
                                // }
                                var canvas2 = canvas.children[1].firstElementChild;
                                if(canvas2.nodeName == "CANVAS"){
                                    canvas2.width = w - 50;
                                    canvas2.height = h - 150;
                                }
                            }
                        }
                    }
                }
                var map = child.firstElementChild;
                //console.log(map);
                if(map.nodeName == "AGM-MAP"){
                    map.style.height = (h - heightDelta) + 'px';
                }
            }

            var stopDrag = function(e) {
                document.documentElement.removeEventListener('mousemove', doDrag, false);    document.documentElement.removeEventListener('mouseup', stopDrag, false);
            }

            resizer.addEventListener('mousedown', initDrag, false);
        });
        for(var i = 0; i < rzcs.length; i++){
            let child: any = rzcs[i];
            
        }
    }


    ngOnInit() {
      /*  this._apiService.getProfileBySimulation(this.idSimulation).subscribe(response => {
            if (response.success) {
                this.n_size = response.data.size;
                this.modelEnv = JSON.parse(response.data.enviroments);
            }
        }, error => {
            console.log(error);
        });*/
        console.log("WINDOWS: "+window['magic']);
        console.log(this.idSimulation);
        //this.videoList.play = true;
        //this.videoList.embed = this.getCodeFromUrl('https://www.youtube.com/embed/_V0pg5xVE6M');
        this.createComponent();
        this.chatReference.instance.getData = (data) => {
            if(data.type == 100){
                this.timerCount = parseInt(data.data, 10);
                if(this.timerCount > 0){
                    //this.simulationOn = true;
                    if(!this.simulationOn){
                        this.hideInitButton = true;
                    }

                    if(!this.showInitialVideo){
                        this.openDialog();
                        this.showInitialVideo = true;
                    }

                }
                var seconds = this.timerCount;
                var days = Math.floor(seconds / (3600*24));
                seconds  -= days*3600*24;
                var hrs   = Math.floor(seconds / 3600);
                seconds  -= hrs*3600;
                var mnts = Math.floor(seconds / 60);
                seconds  -= mnts*60;
                var tmp = '';
                if(days > 0){
                    tmp = days + " days, ";
                }
                if(hrs < 10){ tmp += "0" + hrs; }else{ tmp += hrs; } tmp += ":";
                if(mnts < 10){ tmp += "0" + mnts; }else{ tmp += mnts; } tmp += ":";
                if(seconds < 10){ tmp += "0" + seconds; }else{ tmp += seconds; }
                this.timerInfo =  tmp;
                console.log("asdasdasd",this.timerInfo);
            }else if (data.type === 700) {
                var ms= JSON.parse(data.data);
                if(ms.file){
                    this.updateDocuments();
                }
            }
        };
        this._apiService.getSimulation(this.idSimulation).subscribe(response => { 
            
          
                           this.idSimulationTmp = response.data.tmpId;
                        
                           this.chatReference.instance.sendSimId(this.idSimulationTmp);
                           
                    }, error => {
                        console.log(error);
                    });

       
   
    }

    simulationidTMP(){

       
                  console.log(this.idSimulationTmp);
    }

    keyboardToogle(){
        console.log("WINDOWS: "+window['magic']);
        window['magic'] = ! window['magic'];
        console.log("WINDOWS: "+window['magic']);
    }
    startSimulation() {
                //const scope = this;
                /*window['WebReceiver'] = function (args) {
                    const data = args.split(':');
                    switch (data[0]) {
                        case 'UpdateFromUnity':
                            scope.updateFromUnity(data[1]);
                    }
                };*/
        console.log("SIMMMM",this.idSimulation);
       
        
        this.simulationOn = true;
        this.simStart = true;
        this.simStop = false;
        console.log(this.idSimulation);
        this._apiService.StartSimulation(this.idSimulationTmp).subscribe(result => console.log(result));
        
    }

    startSimulation2() {
        
                window['gameInstance'] = window['UnityLoader'].instantiate(
                    'gameContainer', './' + this.pathUnity + '/Build/HTML.json',
                    {onProgress: window['UnityProgress']}
                );
                console.log("WINDOWS: "+window['magic']);
                window['magic'] = ! window['magic'];
                console.log("WINDOWS: "+window['magic']);
                const scope = this;

                window['WebReceiver'] = function (args) {
                    const data = args.split(':');
                    switch (data[0]) {
                        case 'UpdateFromUnity':
                           // scope.updateFromUnity(data[1]);
                          
                        case 'getMapSize':
                          scope.getMapSize();
                          break;
            
                       /* case 'GetUserData':
                          scope.GetUserData();
                          break;
                    */
                    }
                };
            }

    setFullscreen() {
        window['gameInstance'].SetFullscreen(true);
    }

    runUnityLoader() {
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = './' + this.pathUnity + '/Build/UnityLoader.js';
        script.async = true;
        script.defer = true;
        this.body.appendChild(script);
    }

    runUnityProgress() {
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = './' + this.pathUnity + '/TemplateData/UnityProgress.js';
        script.async = true;
        script.defer = true;
        this.body.appendChild(script);
    }

    finishSimulation() {

        console.log(this.idSimulation);
        console.log(this.idSimulationTmp);


        this._apiService.FinishSimulation(this.idSimulation, this.idSimulationTmp).subscribe(
            (result) => {
                console.log(result['state']);
               /* if (result['state'] === 1) {
                    this.openSnackBarFinish('Simulación Finalizada', 'Aceptar');
                    console.log('Simulación Finalizada');
                }*/
            }
        );
        this.simulationOn = false;
        this.simStart = false;
        this.simStop = true;
    }

    searchDisasterCellsById(id) {
        var fc = null;
        for (var i = 0; i < this.disasterCells.length; i++) {
        if (this.disasterCells[i].id == id) {
            fc = this.disasterCells[i];
            break;
        }      
        }
        return fc;
    }

    WebSender(args) {
        // si hay argumentos
        if (args.length > 0) {
            let msgToUnity = args[0] + '*';
            // si hay mas de un argumento
            if (args.length > 1) {
      
                // empieza de 1 ya que 0 ya se incluyo
                for (let i = 1; i < args.length; i++) {
                    msgToUnity += args[i].toString();
                    // si i no es el valor final del array
                    if (i + 1 < args.length) {
                        msgToUnity += ',';
                    }
                }
            }
            console.log(msgToUnity);
            window['gameInstance'].SendMessage('UnityCOM', 'UnityReceiver', msgToUnity);
        }
      }

    getMapSize(){
        var n = this.n_size;
        this.WebSender(["setMapSize",n.toString()]);
    }

    cellPathID(id) {
        var ij = this.getIJ(id);
        var borders = this.cellBorders(ij[0], ij[1]);
        var path = [
        { lat: borders[0], lng: borders[1] },             
        { lat: borders[0], lng: borders[3] },
        { lat: borders[2], lng: borders[3] }, 
        { lat: borders[2], lng: borders[1] },             
        { lat: borders[0], lng: borders[1] }
        ];

        return path;
    }

    getIJ(id) {
        
        var i = Math.floor(parseInt(id) / this.n_size);
        var j = Number(parseInt(id) %  this.n_size);
        return [i, j];
    }
  
    cellBorders(i, j) {
      var tc = this.cellSizeLatLng();
      var latA = this.latUL + tc[0]*i;
      var lngA = this.lngUL + tc[1]*j;
      var latB = this.latUL + tc[0]*(i+1);
      var lngB = this.lngUL + tc[1]*(j+1);
      return [latA, lngA, latB, lngB];
    }

    cellSizeLatLng() {
        
         var dLat = (this.latBR - this.latUL) / this.n_size;
         var dLng = (this.lngBR - this.lngUL) / this.n_size;
         return [dLat, dLng];
       }


   render(fcs) {
        var threshold = 0;
        if (this.simStart) { // For simulation show states > 1
            threshold = 1; 
            
          } 
      
          var arraybits='';
          var bits=0;
          var decodeBintoInt=0;
          var decodeBintToIntEmpty=0;
          var decodeIntEmptySum=0;
          var binary='';
          var binary2='';
          var incempty=0;
          var incemptychk=false;
          //Parse Byte array to binari array
          for (var b=0; b < fcs.length; b++) {
            arraybits+=("000000000"+(fcs[b]).toString(2)).substr(-7);
          }
      
          for (var b=0; b < this.n_size*this.n_size; b++) {
            
            if(arraybits[bits]=='1' && arraybits[bits+1]=='1' && arraybits[bits+2]=='1'){
              incemptychk=true;
            
              for(var n=bits+3;n<bits+10;n++){
                binary2+=arraybits[n];
              }
              decodeBintToIntEmpty=this.decodeFun(binary2);
              decodeIntEmptySum+=decodeBintToIntEmpty;
              bits=bits+10;
              binary2='';
              decodeBintToIntEmpty=0;
              
              while(incemptychk==true){
              
                if(arraybits[bits]=='1' && arraybits[bits+1]=='1' && arraybits[bits+2]=='1'){
              
                  for(var n=bits+3;n<bits+10;n++){
                    binary2+=arraybits[n];
                  }
                  decodeBintToIntEmpty=this.decodeFun(binary2);
                  decodeIntEmptySum+=decodeBintToIntEmpty;
                  bits=bits+10;
                }
                else{
                  for(var n=bits;n<bits+3;n++){
                    binary+=arraybits[n];
                  }
                  decodeBintoInt=this.decodeFun(binary);
                  incemptychk=false;
                  bits=bits+3;
                  b=b+decodeIntEmptySum;
                }
          
                binary='';
                binary2=''; 
              }
             
              decodeIntEmptySum=0;
      
              if( decodeBintoInt > threshold && incemptychk==false){
                
                var fc1 = this.searchDisasterCellsById(b);
               
                if (fc1 != null) {
                  if (fc1.state != decodeBintoInt)
                    fc1.state = decodeBintoInt;
                } else {             
                    this.disasterCells.push({
                      id: b,
                      path: this.cellPathID(b),
                      state: decodeBintoInt
                    });
                  }
              }
              
            }
      
            else{
          
              for(var o=bits;o<bits+3;o++){
                binary+=arraybits[o];
              }
              decodeBintoInt=this.decodeFun(binary);
            
              if(decodeBintoInt > threshold){
                var fc1 = this.searchDisasterCellsById(b);
               
                if (fc1 != null) {
                  if (fc1.state != decodeBintoInt)
                    fc1.state = decodeBintoInt;
                } else {
                    this.disasterCells.push({
                      id: b,
                      path: this.cellPathID(b),
                      state: decodeBintoInt
                    });
                  }
              }
             
              binary='';
             bits+=3;
            }
            if(decodeBintoInt != 0)
            {
              var toSend = b.toString()+decodeBintoInt.toString();
              this.WebSender(["sendData", toSend]);
            }
            
          }
        }
       

    decodeFun(numbin){
        return parseInt(numbin,2);
    }

    selectFile(event, name, index) {
       if (event.target.files && event.target.files.length > 0) {
            this.files[name] = event.target.files[0];
            this.file.name =  event.target.files[0].name;

        }
        else {
            delete this.files[name];
        }
    }

    uploadFile() {
        var formData = new FormData();
        for (let i in this.files) {
            if (this.files[i]) {
                var file = this.files[i];
                formData.append("files", file, file.name);
                
                //this._apiService.uploadFile(this.idSimulation, formData).subscribe(result => console.log(result));
                //this.webSocket.setValues({type: 8, sid: this.simuId}).subscribe();
            }
        }
        formData.append("displayName", this.file.name);
        formData.append("UserOwner", this.simulationRole.id);
        formData.append("idSimulation", this.idSimulation);
        this.file.name = "";
        this._uploadService.upload(formData).subscribe(
            (res) => {
                if(res["body"] && res["body"].docs.length){
                    var file = res["body"].docs[0];
                    this.chatReference.instance.sendMessage(file);
                }
            }
          );
        this.files = {};
    }

    getEmbedURL(item) {
        return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + item.embed + '?autoplay=1');
    }

    refreshFile() {
        this.updateDocuments();
        this.webSocket.setValues({type: 8, sid: this.simuId}).subscribe();
    }

    getFileURL(file){
        return this._apiService.URL_BASE + "Document/" + file.id + "/download";
      }

    getCodeFromUrl(urlJson) {
        let embedCode = urlJson.split("/")[4];
        return embedCode;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.simulationOn) {
            document.getElementById('#canvas').style.width = '100%';
        }
    }

  
    mapReady(map) {
       this.map = map;
       
    }

    openSnackBarFinish(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 5000
        });
        setTimeout((router: Router) => {
            this._router.navigate(['..'], {relativeTo: this._route}).then();
            window.location.reload();
        }, 5000);  
    }

    evaluate(a){
        //console.log(a);
        this.openAlternativesDialog({edit: true, alternative: a}, [], {});
        }


        openAlternativesDialog(data, alternatives, eventArgs){

            if(window['magic']){
                window['magic'] = ! window['magic']; 
            }
            const scrollStrategy = this.overlay.scrollStrategies.reposition();
            let question = data.eventNameFull;


                if(data.edit){
                    var tmp = {};
                    for(let i in data){
                        tmp[i] = data[i];
                    }
                    tmp["simulationRole"] = this.simulationRole;
                    tmp["question"] = data.alternative.eventName;
                    const dialogRef = this._dialog.open(AlternativesSimulationComponent, {
                        autoFocus: true,
                        maxWidth: '650px',
                        width: '98%',
                        scrollStrategy,
                        data: tmp
                    });
            
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                    });
                }else{
                    const dialogRef = this._dialog.open(AlternativesSimulationComponent, {
                        autoFocus: false,
                        maxWidth: '650px',
                        width: '98%',
                        scrollStrategy,
                        data: {
                            question: JSON.parse("{\"x\":\"" + question +"\"}")["x"],
                            alternatives: alternatives,
                            idSimulation: this.idSimulation,
                            simulationRole: this.simulationRole,
                            id_guion: data.id_guion,
                            yurl: this.getCodeFromUrl(eventArgs.yurl),
                            done: (d) => {
                                this.webSocket.setValues({type: 501}).subscribe();
                            }
                        }
                    });
            
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                    });
                }
            }

            isDefined(obj) {
                if (typeof obj == 'undefined') {
                  return false;
                }
            
                if (!obj) {
                  return false;
                }
            
                return obj != null;
            }

            toggle(event: MatSlideToggleChange) {
               
                if(event.checked==true){                   
                   this.container3D = true;
                   this.containerinfo2 = false;
                }
                else{                 
                    this.container3D = false;
                    this.containerinfo2 = true;
                    
                }
            }

            openDialog(): void {
                const dialogRef = this._dialog.open(DialogOverviewExampleDialog, {
                  data: { 
                         yurl:this.videoList2.embed
                        },
                  autoFocus: false,
                  maxWidth: '650px',
                  width: '98%',                     
                });
            
                dialogRef.afterClosed().subscribe(result => {
                  console.log('The dialog was closed');                  
                });
              }
        

}

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'dialog-overview-example-dialog.html',
  })
  export class DialogOverviewExampleDialog {
  
    constructor(
      public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
      @Inject(MAT_DIALOG_DATA) public data: MatDialog) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }
