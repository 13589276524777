import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'disaster'
})
export class DisasterPipe implements PipeTransform {

    transform(value: string, args?: string): any {
        if (!args) {
            switch (value) {
                case 'fire':
                    return 'Incendio';
                case 'flood':
                    return 'Inundación';
                case 'earthquake':
                    return 'Terremoto';
                default:
                    return '';
            }
        } else if (args === 'id') {
            switch (value) {
                case 'fire':
                    return 1;
                case 'flood':
                    return 2;
                case 'earthquake':
                    return 3;
                default:
                    return '';
            }
        }
    }

}
