import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../../../../../services/api.service';
import {Event} from '../../../../../../models/Event';

@Component({
    selector: 'app-edit-event',
    templateUrl: './edit-event.component.html',
    styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {
    eventFormGroup: FormGroup;
    eventTypes = [];
    event: Event;

    constructor(public dialogRef: MatDialogRef<EditEventComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private formBuilder: FormBuilder) {
        this.dialogRef.disableClose = true;
        this.event = this.data.event;
        this.eventFormGroup = this.formBuilder.group({
            nameCtrl: ['', Validators.required],
            descriptionCtrl: ['', Validators.required],
            eventTypeCtrl: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.apiService.getEventTypes().subscribe(response => {
            if (response.success) {
                this.eventTypes = response.data;
                this.eventFormGroup.patchValue({
                    nameCtrl: this.event.name,
                    descriptionCtrl: this.event.description,
                    eventTypeCtrl: this.event.eventTypeId
                });
            } else {
                this.dialogRef.close();
            }
        }, error => {
            if (error) {
                this.dialogRef.close();
            }
        });
    }

    updateEvent() {
        if (this.eventFormGroup.valid) {
            const eventParams = {};
            const event: Event = {
                id: this.event.id,
                name: this.eventFormGroup.get('nameCtrl').value,
                description: this.eventFormGroup.get('descriptionCtrl').value,
                params: JSON.stringify(eventParams),
                eventTypeId: this.eventFormGroup.get('eventTypeCtrl').value,
                disasterId: this.event.disasterId
            };
            this.dialogRef.close({
                data: event
            });
        }
    }

}
