export class profileFlood {
    private colors: string[] = ["white", "yellow", "#554545"];
    constructor() {}
    
        getData(){    
            let profile = {
                data: {
                
                    colors: this.colors
                } 
            }
            return profile;
        }
}