import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VariableGroup} from '../../../../../../models/VariableGroup';
import {Variable} from '../../../../../../models/Variable';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../../../../../services/api.service';

@Component({
    selector: 'app-edit-variable',
    templateUrl: './edit-variable.component.html',
    styleUrls: ['./edit-variable.component.scss']
})
export class EditVariableComponent implements OnInit {

    variableFormGroup: FormGroup;
    variableGroups: VariableGroup[] = [];
    variable: Variable;
    variables: Variable[] = [];
    variablesSelected = [];
    disasterId;

    constructor(public dialogRef: MatDialogRef<EditVariableComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private formBuilder: FormBuilder) {
        this.dialogRef.disableClose = true;
        this.variableFormGroup = this.formBuilder.group({
            nameCtrl: ['', Validators.required],
            variableGroupCtrl: ['', Validators.required],
            valueCtrl: ['', Validators.required],
            weightCtrl: ['', Validators.required]
        });
        this.disasterId = data.disasterId;
        this.variable = data.variable;
        this.variableFormGroup.patchValue({
            nameCtrl: this.variable.name,
            variableGroupCtrl: this.variable.variableGroupId,
            valueCtrl: this.variable.value,
            weightCtrl: this.variable.weight
        });
    }

    ngOnInit() {
        this.apiService.getVariableGroupsByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.variableGroups = response.data;
            } else {
                this.dialogRef.close();
            }
        }, error => {
            if (error) {
                this.dialogRef.close();
            }
        });
        this.apiService.getVariablesByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.variables = response.data;
                const ecRel = JSON.parse(this.variable.ecRel);
                for (let i = 0; i < this.variables.length; i++) {
                    for (let j = 0; j < ecRel.length; j++) {
                        if (this.variables[i].id === ecRel[j].id) {
                            this.variables[i] = ecRel[j];
                            this.variablesSelected.push(ecRel[j]);
                        }
                    }
                }
            }
        });
    }

    updateVariable() {
        if (this.variableFormGroup.valid) {
            const variable: Variable = {
                name: this.variableFormGroup.get('nameCtrl').value,
                value: this.variableFormGroup.get('valueCtrl').value,
                weight: this.variableFormGroup.get('weightCtrl').value,
                ecRel: JSON.stringify(this.variablesSelected),
                disasterId: this.data.disasterId,
                variableGroupId: this.variableFormGroup.get('variableGroupCtrl').value
            };
            this.dialogRef.close({
                data: variable
            });
        }
    }

}
