import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {ApiService} from '../../../../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Script} from '../../../../../models/Script';
import {DisasterPipe} from '../../../../../pipes/disaster.pipe';
import {Simulation} from '../../../../../models/Simulation';
import {SimulationRole} from '../../../../../models/SimulationRole';

@Component({
    selector: 'app-new-simulation',
    templateUrl: './new-simulation.component.html',
    styleUrls: ['./new-simulation.component.scss']
})
export class NewSimulationComponent implements OnInit {
    title = '';
    isLinear = false;
    simulationFormGroup: FormGroup;
    cascade = false;
    disaster;
    disasterID: string;
    scenarios = [];
    scripts: Script[] = [];
    profiles = [];
    predefinedUsers = false;
    userFilter = '';
    users = [];
    usersFiltered = [];
    simulationUsersFilter = '';
    simulationUsers = [];
    simulationUsersFiltered = [];
    roleCommands = [];
    roleLevels = [];
    scriptInputStatus = true;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                private formBuilder: FormBuilder,
                private apiService: ApiService,
                private disasterPipe: DisasterPipe) {
        this.title = this.route.snapshot.data.title;
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterID = this.disasterPipe.transform(this.disaster, 'id');
        this.simulationFormGroup = formBuilder.group({
            nameCtrl: ['', Validators.required],
            scenarioCtrl: ['', Validators.required],
            scriptCtrl: [{value: '', disabled: this.scriptInputStatus}, Validators.required],
            profileCtrl: [{value: '', disabled: true}, Validators.required],
            cascadeStatusCtrl: [''],
            cascadeCtrl: [''],
        });
        this.simulationFormGroup.patchValue({
            cascadeCtrl: 0
        });
    }

    ngOnInit() {
        this.apiService.getScenariosByDisaster(this.disasterID).subscribe(response => {
            this.scenarios = response.data;
        }, error => {
            if (error) {
            }
        });

        this.apiService.getUsers().subscribe(response => {
            if (response.success) {
                this.users = response.data;
                this.usersFiltered = response.data;
            }
        }, error => {
            if (error) {
            }
        });

        this.apiService.getSimulationRoleCommands().subscribe(response => {
            if (response.success) {
                this.roleCommands = response.data;
            }
        }, error => {
            if (error) {
            }
        });

        this.apiService.getSimulationRoleLevels().subscribe(response => {
            if (response.success) {
                this.roleLevels = response.data;
            }
        }, error => {
            if (error) {
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    filterData(filterValue: string, source) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        switch (source) {
            case 'users':
                this.usersFiltered = this.users.filter(user => user.email.includes(filterValue));
                break;
            case 'simulationUsers':
                this.simulationUsersFiltered = this.simulationUsers.filter(user => user.email.includes(filterValue));
                break;
        }
    }

    loadProfiles(scenario) {
        this.simulationFormGroup.controls['profileCtrl'].reset();
        this.apiService.getProfilesByScenario(scenario).subscribe(response => {
            if (response.success) {
                this.simulationFormGroup.controls['profileCtrl'].enable();
                this.profiles = response.data;
            }
        }, error => {
            if (error) {
            }
        });
    }

    loadScripts(profile) {
        this.simulationFormGroup.controls['scriptCtrl'].reset();
        this.apiService.getScriptsByProfile(profile).subscribe(response => {
            if (response.success) {
                this.simulationFormGroup.controls['scriptCtrl'].enable();
                this.scripts = response.data;
            }
        }, error => {
            if (error) {
            }
        });
    }

    addSimulationUser(user, index) {
        this.simulationUsers.unshift(
            {
                name: user.userName,
                email: user.email,
                userId: user.id,
                simulationId: 0,
                simRoleLevelId: 0,
                simRoleCommandId: 0,
            });
        this.users.splice(index, 1);
        this.usersFiltered = this.users;
        this.simulationUsersFiltered = this.simulationUsers;
        this.userFilter = '';
    }

    removeSimulationUser(user, index) {
        this.users.unshift({
            id: user.userId,
            name: user.userName,
            email: user.email
        });
        this.simulationUsers.splice(index, 1);
        this.usersFiltered = this.users;
        this.simulationUsersFiltered = this.simulationUsers;
        this.userFilter = '';
    }

    findNameById(id, type) {
        let array: any = [];
        switch (type) {
            case 'scenario':
                array = this.scenarios.filter(result => result.id === id);
                break;
            case 'script':
                array = this.scripts.filter(result => result.id === id);
                break;
            case 'profile':
                array = this.profiles.filter(result => result.id === id);
                break;
        }
        if (array[0]) {
            return array[0].name;
        }
    }

    createSimulation() {
        if (this.simulationFormGroup.valid) {
            const simulation: Simulation = {
                name: this.simulationFormGroup.get('nameCtrl').value,
                predefinedUsers: 0,
                cascade: 0,
                scriptId: this.simulationFormGroup.get('scriptCtrl').value,
                statusId: 1
            };
            if (this.cascade) {
                simulation.cascade = this.simulationFormGroup.get('cascadeCtrl').value;
            }
            if (this.predefinedUsers) {
                simulation.predefinedUsers = 1;
            }
            this.apiService.createSimulation(simulation).subscribe(response => {
                if (response.success) {
                    if (this.predefinedUsers) {
                        console.log(simulation);
                        const simulationRoles: SimulationRole[] = [];
                        for (const user of this.simulationUsersFiltered) {
                            simulationRoles.push({
                                moderator: 0,
                                userId: user.userId,
                                simulationId: response.data.id,
                                //simulationId: response.data.tmpId,
                                simulationRoleCommandId: user.simRoleCommandId,
                                simulationRoleLevelId: user.simRoleLevelId
                            });
                        }
                        this.apiService.createSimulationRoles(simulationRoles).subscribe(response2 => {
                            if (response2.success) {
                                this.openSnackBar(response2.message, 'Aceptar');
                                this.router.navigate(['..'], {relativeTo: this.route}).then();
                            } else {
                                this.openSnackBar(response2.message, 'Aceptar');
                                this.router.navigate(['..'], {relativeTo: this.route}).then();
                            }
                        }, error => {
                            if (error) {
                                this.openSnackBar('No se han podido registrar los usuarios de la simulación', 'Aceptar');
                                this.router.navigate(['..'], {relativeTo: this.route}).then();
                            }
                        });
                    } else {
                        this.openSnackBar(response.message, 'Aceptar');
                        this.router.navigate(['..'], {relativeTo: this.route}).then();
                    }
                } else {
                    this.openSnackBar(response.message, 'Aceptar');
                }
            }, error => {
                if (error) {
                    this.openSnackBar('No se ha podido crear la sesión', 'Aceptar');
                }
            });
        } else {
            this.openSnackBar('Falta completar campos requeridos', 'Aceptar');
        }
    }
}
