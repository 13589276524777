import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
//import { Observable } from 'rxjs/Observable';
//import { ChatService } from '../../providers/chat.service';
import {ApiService} from '../../../../../../services/api.service';
import {Router, ActivatedRoute, Params, UrlSegment} from '@angular/router';
import {WebsocketService} from '../../../../../../services/websocket.service';
import {AuthService} from '../../../../../../services/auth.service';

interface Message{
  user: string;
  name_user:string;
  message: string;
  date?;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [WebsocketService]
})
export class ChatComponent implements OnInit {

  public message: string = "";
  element: any;
  public user;
  public user_id;
  public user_name;
  public simuId;
  private webSocket2;

  public chats: Message[] = [];
  public messages: Message[] = [];

  public getData: any = null;
  public idSimulationTmp;

  constructor(
    private _apiService: ApiService,
    private _webSocket: WebsocketService,
    private _auth: AuthService,
    private _route: ActivatedRoute
  ) {
    this._auth.getUser().subscribe(response => {
      if (response.success) {
       
        this.user_id =response.data.id ;
        this.user_name = response.data.userName;

      }
      }, error => {
          console.log(error);
      });
 
    this.simuId = _route.snapshot.params.id;

    this._apiService.getSimulation(this.simuId).subscribe(response => { 
      
                
                  if (response.success) {
                  
                     this.idSimulationTmp = response.data.tmpId;
                  
                  }
      
              }, error => {
                  console.log(error);
              });
     

 //  console.log(this.element.scrollHeight);
    this.webSocket2 = new WebsocketService();
           this.webSocket2.connectSocket();
           
            this.webSocket2.getValues = data => {

              if(this.getData != null){
                this.getData(data);
              }
           
              if (data.type === 700) {          
              
                  var ms= JSON.parse(data.data);
               
                  var obj = {user:ms.usr,name_user:ms.usr_n,message:ms.msg,date:Date};
                  if(ms.file){
                    obj["file"] = ms.file;
                  }
                  this.chats.push(obj);
                  setTimeout( () => {
                  this.element.scrollTop = this.element.scrollHeight;
              },20)
            }
          }
          };

  ngOnInit(){
    this.element = document.getElementById('app-message');
  }

  sendMessage(file?){
    
      var obj = {type: 5, usr:this.user_id, usr_n:this.user_name, msg: this.message};
      if(file && file.id && file.id > 0){
        obj["file"] = file;
      }

      this.webSocket2.setValues(obj).subscribe();
  /*   if (this.message.length === 0) {
        return;
      }

      this._cs.addMessage( this.message )
              .then( () => this.message = '' )
              .catch( ( err ) => console.log('Error al enviar', err ) );


    }*/

    this.message='';

  }

  sendSimId(simid){
    
    var obj = {type: 100, sim: simid};
    this.webSocket2.setValues(obj).subscribe();
}

  getFileURL(file){
    return this._apiService.URL_BASE + "Document/" + file.id + "/download";
  }

  open(chat){
    if(chat.file){
      var url = this.getFileURL(chat.file);
      window.open(url, "_blank");
      console.log(url);
    }
  }
}
