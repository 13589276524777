import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {

    transform(value: any, arg1?: string, arg2?: string): any {
        if (!arg1 || arg1 === 'full') {
            const days = Math.floor(value / 86400);
            const hours = Math.floor((value % 86400) / 3600);
            const minutes = Math.floor(((value % 86400) % 3600) / 60);
            const seconds = ((value % 86400) % 3600) % 60;
            return (days > 0 ? days + 'd ' : '') + ('00' + hours).slice(-2) + ':' +
                ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
        } else {
            switch (arg1) {
                case 'days':
                    return ((value / 60) / 60) / 24;
                case 'hours':
                    return (value / 60) / 60;
                case 'minutes':
                    return value / 60;
                case 'seconds':
                    return value;
                case 'inverse':
                    switch (arg2) {
                        case 'days':
                            return ((value * 60) * 60) * 24;
                        case 'hours':
                            return (value * 60) * 60;
                        case 'minutes':
                            return value * 60;
                        case 'seconds':
                            return value;
                    }
                    return;
                case 'translate':
                    switch (value) {
                        case 'days':
                            return 'Días';
                        case 'hours':
                            return 'Horas';
                        case 'minutes':
                            return 'Minutos';
                        case 'seconds':
                            return 'Segundos';
                    }
                    return;
            }
        }
    }

}
