import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ApiService} from '../../../../../../services/api.service';
import {SimulationRoleCommand} from "../../../../../../models/SimulationRoleCommand";
import {SimulationRoleLevel} from "../../../../../../models/SimulationRoleLevel";
import {User} from "../../../../../../models/User";
import {AuthService} from "../../../../../../services/auth.service";
import {SimulationRole} from "../../../../../../models/SimulationRole";

@Component({
    selector: 'app-role-simulation',
    templateUrl: './role-simulation.component.html',
    styleUrls: ['./role-simulation.component.scss']
})
export class RoleSimulationComponent implements OnInit {

    simulationId;
    user: User;
    simulationRole: SimulationRole;
    roleFormGroup: FormGroup;
    roleCommands: SimulationRoleCommand[] = [];
    roleLevels: SimulationRoleLevel[] = [];

    constructor(public dialogRef: MatDialogRef<RoleSimulationComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder,
                private authService: AuthService,
                private apiService: ApiService,
                private snackBar: MatSnackBar) {
        this.simulationId = this.data.simulationId;
        dialogRef.disableClose = true;
        this.roleFormGroup = this.formBuilder.group({
            moderator: ['', Validators.required],
            roleCommand: ['', Validators.required],
            roleLevel: ['', Validators.required]
        });
        this.roleFormGroup.get('moderator').setValue(false);
    }

    ngOnInit() {
        this.apiService.getSimulationRoleCommands().subscribe(response => {
            if (response.success) {
                this.roleCommands = response.data;
            } else {
                this.dialogRef.close();
            }
        }, error => {
            if (error) {
                this.dialogRef.close();
            }
        });
        this.apiService.getSimulationRoleLevels().subscribe(response => {
            if (response.success) {
                this.roleLevels = response.data;
            } else {
                this.dialogRef.close();
            }
        }, error => {
            if (error) {
                this.dialogRef.close();
            }
        });
        this.authService.getUser().subscribe(response => {
            if (response.success) {
                this.user = response.data;
                this.apiService.getSimulationRoleBySimulationAndUser(this.simulationId, this.user.id).subscribe(response2 => {
                    if (response2.success) {
                        this.simulationRole = response2.data;
                        this.roleFormGroup.patchValue({
                            roleCommand: this.roleCommands.find(roleCommand => roleCommand.id === this.simulationRole.simulationRoleCommandId),
                            roleLevel: this.roleLevels.find(roleLevel => roleLevel.id === this.simulationRole.simulationRoleLevelId)
                        });
                    } else {
                        if (this.user.roleId === 1 || this.user.roleId === 2) {
                            this.roleFormGroup.get('moderator').setValue(true);
                            this.roleFormGroup.get('roleCommand').disable();
                            this.roleFormGroup.get('roleLevel').disable();
                        }
                    }
                });
            } else {
                this.dialogRef.close();
            }
        }, error => {
            if (error) {
                this.dialogRef.close();
            }
        });
    }

    moderatorStatus() {
        if (!this.roleFormGroup.get('moderator').value) {
            this.roleFormGroup.get('roleCommand').disable();
            this.roleFormGroup.get('roleLevel').disable();
        } else {
            this.roleFormGroup.get('roleCommand').enable();
            this.roleFormGroup.get('roleLevel').enable();
        }
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500
        });
    }

    selectRole() {
        if (this.roleFormGroup.valid) {
            /*if (this.roleFormGroup.get('moderator').value) {
                this.dialogRef.close(true);
            } else {*/
                if (!this.simulationRole) {
                    const simulationRole: SimulationRole = {
                        simulationId: this.simulationId,
                        moderator: 0,
                        simulationRoleCommandId: this.roleFormGroup.get('roleCommand').value.id,
                        simulationRoleLevelId: this.roleFormGroup.get('roleLevel').value.id,
                        userId: this.user.id
                    };

                    if (this.roleFormGroup.get('moderator').value) {
                        simulationRole.moderator = 1;
                        simulationRole.simulationRoleCommandId = 1,
                        simulationRole.simulationRoleLevelId = 1
                    } else {
                        simulationRole.moderator = 0;
                        
                    }
                    this.apiService.createSimulationRole(simulationRole).subscribe(response => {
                        if (response.success) {
                            this.openSnackBar(response.message, 'Aceptar');
                            this.dialogRef.close(true);
                        } else {
                            this.openSnackBar(response.message, 'Aceptar');
                        }
                    }, error => {
                        if (error) {
                            this.openSnackBar('No se ha podido crear el rol de simulación', 'Aceptar');
                        }
                    });
                } else {
                    this.simulationRole.simulationRoleCommandId = this.roleFormGroup.get('roleCommand').value.id;
                    this.simulationRole.simulationRoleLevelId = this.roleFormGroup.get('roleLevel').value.id;
                    
                    if (this.roleFormGroup.get('moderator').value) {
                        this.simulationRole.moderator = 1;
                      
                    } else {
                        this.simulationRole.moderator = 0;
                        
                    }
                    this.apiService.updateSimulationRole(this.simulationRole.id, this.simulationRole).subscribe(response => {
                        if (response.success) {
                            this.openSnackBar(response.message, 'Aceptar');
                            this.dialogRef.close(true);
                        } else {
                            this.openSnackBar(response.message, 'Aceptar');
                        }
                    }, error => {
                        if (error) {
                            this.openSnackBar('No se ha podido actualizar el rol de simulación', 'Aceptar');
                        }
                    });
                }
            }
        /*}**/ else {
            this.openSnackBar('Debe asignarse un rol para acceder a la simulacion', 'Aceptar');
        }
    }

}
