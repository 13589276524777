import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  SERVER_URL: string = "http://" + document.location.hostname + ":5000";

  constructor(private httpClient: HttpClient) { }

  public upload(data) {
    let uploadURL = `${this.SERVER_URL}/api/Document/upload`;

    console.log(uploadURL);

    return this.httpClient.post<any>(uploadURL, data, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
