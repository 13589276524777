import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PlatformComponent} from './components/platform/platform.component';
import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from './components/platform/home/home.component';
import {DisasterComponent} from './components/platform/disaster/disaster.component';
import {SimulationsComponent} from './components/platform/disaster/simulations/simulations.component';
import {SimulationComponent} from './components/platform/disaster/simulations/simulation/simulation.component';
import {NewSimulationComponent} from './components/platform/disaster/simulations/new-simulation/new-simulation.component';
import {ResultsComponent} from './components/platform/disaster/results/results.component';
import {ResultComponent} from './components/platform/disaster/results/result/result.component';
import {ScriptsComponent} from './components/platform/disaster/scripts/scripts.component';
import {NewScriptComponent} from './components/platform/disaster/scripts/new-script/new-script.component';
import {ScriptComponent} from './components/platform/disaster/scripts/script/script.component';
import {ProfilesComponent} from './components/platform/disaster/profiles/profiles.component';
import {ProfileComponent} from './components/platform/disaster/profiles/profile/profile.component';
import {EventsComponent} from './components/platform/disaster/events/events.component';
import {UsersComponent} from './components/platform/users/users.component';
import {NewProfileComponent} from './components/platform/disaster/profiles/new-profile/new-profile.component';
import {AuthGuard} from './guards/auth.guard';
import {AdminGuard} from './guards/admin.guard';
import {VariablesComponent} from './components/platform/disaster/variables/variables.component';

const routes: Routes = [
    {
        path: 'platform',
        component: PlatformComponent,
        children: [
            {
                path: 'home',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                component: HomeComponent,
                data: {
                    title: 'Inicio'
                }
            },
            {
                path: 'settings',
                data: {
                    title: 'Administración'
                },
                children: [
                    {
                        path: 'users',
                        pathMatch: 'full',
                        canActivate: [AuthGuard, AdminGuard],
                        component: UsersComponent,
                        data: {
                            title: 'Usuarios'
                        }
                    }
                ]
            },
            {
                path: ':type', // Tipo de desastre
                component: DisasterComponent,
                children: [
                    {
                        path: 'simulations',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: SimulationsComponent,
                                data: {
                                    title: 'Simulaciones en Ejecución'
                                }
                            },
                            {
                                path: 'new',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: NewSimulationComponent,
                                data: {
                                    title: 'Nueva Simulación'
                                }
                            },
                            {
                                path: ':id',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: SimulationComponent,
                                data: {
                                    title: 'Simulación'
                                }
                            }
                        ]
                    },
                    {
                        path: 'results',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: ResultsComponent,
                                data: {
                                    title: 'Resultados'
                                }
                            },
                            {
                                path: ':id',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: ResultComponent,
                                data: {
                                    title: 'Resultado de Simulación'
                                }
                            }
                        ]
                    },
                    {
                        path: 'scripts',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: ScriptsComponent,
                                data: {
                                    title: 'Guiones'
                                }
                            },
                            {
                                path: 'new',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: NewScriptComponent,
                                data: {
                                    title: 'Nuevo Guión'
                                }
                            },
                            {
                                path: ':id',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: ScriptComponent,
                                data: {
                                    title: 'Guión'
                                }
                            }
                        ]
                    },
                    {
                        path: 'profiles',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: ProfilesComponent,
                                data: {
                                    title: 'Perfiles'
                                }
                            },
                            {
                                path: 'new',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: NewProfileComponent,
                                data: {
                                    title: 'Nuevo Perfil'
                                }
                            },
                            {
                                path: ':id',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: ProfileComponent,
                                data: {
                                    title: 'Perfil Inicial'
                                }
                            }
                        ]
                    },
                    {
                        path: 'variables',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: VariablesComponent,
                                data: {
                                    title: 'Variables'
                                }
                            }
                        ]
                    },
                    {
                        path: 'events',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                canActivate: [AuthGuard],
                                component: EventsComponent,
                                data: {
                                    title: 'Eventos'
                                }
                            }
                        ]
                    },
                    {
                        path: '**',
                        redirectTo: 'simulations'
                    }
                ]
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'home'
            }
        ]
    },
    {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: 'platform'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
