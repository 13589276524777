import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../../../../../services/api.service';
import {Event} from '../../../../../../models/Event';

@Component({
    selector: 'app-new-event',
    templateUrl: './new-event.component.html',
    styleUrls: ['./new-event.component.scss']
})
export class NewEventComponent implements OnInit {
    eventFormGroup: FormGroup;
    eventTypes = [];

    constructor(public dialogRef: MatDialogRef<NewEventComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private formBuilder: FormBuilder) {
        this.dialogRef.disableClose = true;
        this.eventFormGroup = this.formBuilder.group({
            nameCtrl: ['', Validators.required],
            descriptionCtrl: ['', Validators.required],
            eventTypeCtrl: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.apiService.getEventTypes().subscribe(response => {
            if (response.success) {
                this.eventTypes = response.data;
            } else {
                this.dialogRef.close();
            }
        }, error => {
            if (error) {
                this.dialogRef.close();
            }
        });
    }

    createEvent() {
        if (this.eventFormGroup.valid) {
            const eventParams = {};
            const event: Event = {
                name: this.eventFormGroup.get('nameCtrl').value,
                description: this.eventFormGroup.get('descriptionCtrl').value,
                params: JSON.stringify(eventParams),
                eventTypeId: this.eventFormGroup.get('eventTypeCtrl').value,
                disasterId: this.data.disasterId
            };
            this.dialogRef.close({
                data: event
            });
        }
    }

}
