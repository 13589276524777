import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {TimePipe} from '../../../../../pipes/time.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../../services/api.service';
import {DisasterPipe} from '../../../../../pipes/disaster.pipe';
import {Script} from '../../../../../models/Script';
import {EventScript} from '../../../../../models/EventScript';
import {EditScriptComponent} from '../dialogs/edit-script/edit-script.component';
import {RemoveScriptComponent} from '../dialogs/remove-script/remove-script.component';
import {RemoveEventScriptComponent} from '../dialogs/remove-event-script/remove-event-script.component';
import {EditEventScriptComponent} from '../dialogs/edit-event-script/edit-event-script.component';
import {NewEventScriptComponent} from '../dialogs/new-event-script/new-event-script.component';
import {Scenario} from '../../../../../models/Scenario';

@Component({
    selector: 'app-script',
    templateUrl: './script.component.html',
    styleUrls: ['./script.component.scss']
})
export class ScriptComponent implements OnInit {
    title = '';
    disaster;
    disasterId;
    scriptId;
    script: Script;
    scenarios: Scenario[] = [];
    profileName = '';
    eventsScript: EventScript[] = [];
    displayedColumns = ['eventId', 'name', 'time', 'description', 'action'];
    dataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public disasterPipe: DisasterPipe,
                public timePipe: TimePipe,
                private route: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                private dialog: MatDialog,
                private apiService: ApiService) {
        this.title = this.route.snapshot.data.title;
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.disaster, 'id');
        this.scriptId = this.route.snapshot.params.id;
    }

    ngOnInit() {
        this.apiService.getScenariosByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.scenarios = response.data;
            }
        });
        this.apiService.getScript(this.scriptId).subscribe(response => {
            if (response.success) {
                this.script = response.data;
                this.apiService.getProfile(this.script.profileId).subscribe(response2 => {
                    if (response2.success) {
                        this.profileName = response2.data.name;
                    }
                }, error => {
                    if (error) {
                    }
                });
                this.apiService.getEventsScriptByScript(this.scriptId).subscribe(response2 => {
                    if (response2.success) {
                        this.eventsScript = response2.data;
                        this.dataSource = new MatTableDataSource<EventScript>(this.eventsScript);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                    }
                }, error => {
                    if (error) {
                        this.router.navigate(['..'], {relativeTo: this.route}).then();
                    }
                });
            }
        }, error => {
            if (error) {
                this.router.navigate(['..'], {relativeTo: this.route}).then();
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    findNameById(id, type) {
        let element;
        switch (type) {
            case 'scenario':
                element = this.scenarios.find(result => result.id === id);
                break;
            default:
                break;
        }
        return element.name;
    }

    updateScript() {
        const dialogRef = this.dialog.open(EditScriptComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                id: this.scriptId,
                disasterId: this.disasterId,
                disaster: this.disaster
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

    deleteScript() {
        const dialogRef = this.dialog.open(RemoveScriptComponent, {
            maxWidth: '650px',
            width: '98%',
            autoFocus: false,
            data: {
                id: this.scriptId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.router.navigate(['..'], {relativeTo: this.route}).then();
            }
        });
    }

    newEventScript() {
        const dialogRef = this.dialog.open(NewEventScriptComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                disasterId: this.disasterId,
                scriptId: this.scriptId,
                maxTime: this.script.time,
                profileId: this.script.profileId,
                eventsScript: this.eventsScript
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.data.scriptId = this.scriptId;
                result.data.value = JSON.stringify(result.data.value);
                console.log(result.data);
                this.apiService.createEventScript(result.data).subscribe(response => {
                    if (response.success) {
                        this.openSnackBar(response.message, 'Aceptar');
                        this.ngOnInit();
                    } else {
                        this.openSnackBar(response.message, 'Aceptar');
                    }
                }, error => {
                    if (error) {
                        this.openSnackBar('No se ha podido agregar el evento', 'Aceptar');
                    }
                });
            }
        });
    }

    updateEventScript(eventScript) {
        const dialogRef = this.dialog.open(EditEventScriptComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                disasterId: this.disasterId,
                maxTime: this.script.time,
                profileId: this.script.profileId,
                eventScript: eventScript
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let eventScriptResult = result.data;
                eventScriptResult.id = eventScript.id;
                eventScriptResult.scriptId = eventScript.scriptId;
                eventScriptResult.value = JSON.stringify(result.data.value);
                this.apiService.updateEventScript(eventScript.id, eventScriptResult).subscribe(response => {
                    if (response.success) {
                        this.openSnackBar(response.message, 'Aceptar');
                        this.ngOnInit();
                    } else {
                        this.openSnackBar(response.message, 'Aceptar');
                    }
                }, error => {
                    if (error) {
                        this.openSnackBar('No se ha podido actualizar el evento', 'Aceptar');
                    }
                });
            }
        });
    }

    deleteEventScript(eventScriptId) {
        const dialogRef = this.dialog.open(RemoveEventScriptComponent, {
            maxWidth: '650px',
            width: '98%',
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.apiService.deleteEventScript(eventScriptId).subscribe(response => {
                    if (response.success) {
                        this.openSnackBar(response.message, 'Aceptar');
                        this.ngOnInit();
                    } else {
                        this.openSnackBar(response.message, 'Aceptar');
                    }
                }, error => {
                    if (error) {
                        this.openSnackBar('No se ha podido remover el evento', 'Aceptar');
                    }
                });
            }
        });
    }

}
