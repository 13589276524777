import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {ApiService} from '../../../../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Profile} from '../../../../../models/Profile';
import {Script} from '../../../../../models/Script';
import {EventScript} from '../../../../../models/EventScript';
import {Scenario} from '../../../../../models/Scenario';
import {DisasterPipe} from '../../../../../pipes/disaster.pipe';
import {TimePipe} from '../../../../../pipes/time.pipe';
import {NewEventScriptComponent} from '../dialogs/new-event-script/new-event-script.component';
import {RemoveEventScriptComponent} from '../dialogs/remove-event-script/remove-event-script.component';
import {EditEventScriptComponent} from '../dialogs/edit-event-script/edit-event-script.component';

@Component({
    selector: 'app-new-script',
    templateUrl: './new-script.component.html',
    styleUrls: ['./new-script.component.scss']
})
export class NewScriptComponent implements OnInit {
    title = '';
    isLinear = false;
    disaster;
    disasterId;
    timeType = [
        // {
        //    name: 'Segundos',
        //    value: 'seconds'
        // },
        {
            name: 'Minutos',
            value: 'minutes'
        },
        {
            name: 'Horas',
            value: 'hours'
        },
        {
            name: 'Días',
            value: 'days'
        },
    ];
    scenarios: Scenario[] = [];
    profiles: Profile[] = [];
    script: Script;
    eventsScript: EventScript[] = [];
    scriptFormGroup: FormGroup;
    displayedColumns = ['eventId', 'name', 'time', 'description', 'action'];
    dataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public disasterPipe: DisasterPipe,
                public timePipe: TimePipe,
                private route: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                private dialog: MatDialog,
                private formBuilder: FormBuilder,
                private apiService: ApiService) {
        this.title = this.route.snapshot.data.title;
        this.disaster = this.route.snapshot.parent.parent.params.type;
        this.disasterId = this.disasterPipe.transform(this.disaster, 'id');
        this.scriptFormGroup = this.formBuilder.group({
            nameCtrl: ['', Validators.required],
            scenarioCtrl: ['', Validators.required],
            profileCtrl: [{value: '', disabled: true}, Validators.required],
            timeCtrl: ['', Validators.required],
            timeTypeCtrl: ['', Validators.required],
            descriptionCtrl: ['', Validators.required]
        });
        this.scriptFormGroup.patchValue({
            timeTypeCtrl: 'minutes'
        });
        this.dataSource = new MatTableDataSource<EventScript>(this.eventsScript);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngOnInit() {
        this.apiService.getScenariosByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.scenarios = response.data;
            }
        }, error => {
            if (error) {
                this.router.navigate(['..'], {relativeTo: this.route}).then();
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    loadProfiles(scenarioId) {
        this.scriptFormGroup.get('profileCtrl').reset();
        this.apiService.getProfilesByScenario(scenarioId).subscribe(response => {
            if (response.success && response.data) {
                this.profiles = response.data;
                this.scriptFormGroup.controls['profileCtrl'].enable();
            }
        }, error => {
            if (error) {
            }
        });
    }

    findNameById(id, type) {
        let array: any = [];
        switch (type) {
            case 'scenario':
                array = this.scenarios.filter(result => result.id === id);
                break;
            case 'profile':
                array = this.profiles.filter(result => result.id === id);
                break;
        }
        if (array[0]) {
            return array[0].name;
        }
    }

    newEventScript() {
        const dialogRef = this.dialog.open(NewEventScriptComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                disasterId: this.disasterId,
                maxTime: this.timePipe.transform(
                    this.scriptFormGroup.get('timeCtrl').value,
                    'inverse',
                    this.scriptFormGroup.get('timeTypeCtrl').value),
                profileId: this.scriptFormGroup.get('profileCtrl').value,
                eventsScript: this.eventsScript
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.openSnackBar('Evento Agregado al guión', 'Aceptar');
                this.eventsScript.unshift(result.data);
                this.dataSource = new MatTableDataSource<EventScript>(this.eventsScript);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        });
    }

    updateEventScript(elementIndex, eventScript) {
        const dialogRef = this.dialog.open(EditEventScriptComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                disasterId: this.disasterId,
                maxTime: this.timePipe.transform(
                    this.scriptFormGroup.get('timeCtrl').value,
                    'inverse',
                    this.scriptFormGroup.get('timeTypeCtrl').value),
                profileId: this.scriptFormGroup.get('profileCtrl').value,
                eventScript: eventScript
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.openSnackBar('Evento del guión modificado', 'Aceptar');
                const index = elementIndex + this.paginator.pageIndex * this.paginator.pageSize;
                this.eventsScript[index] = result.data;
                this.dataSource = new MatTableDataSource<EventScript>(this.eventsScript);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        });
    }

    deleteEventScript(elementIndex) {
        const dialogRef = this.dialog.open(RemoveEventScriptComponent, {
            maxWidth: '650px',
            width: '98%',
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.openSnackBar('Evento removido del guión', 'Aceptar');
                const index = elementIndex + this.paginator.pageIndex * this.paginator.pageSize;
                this.eventsScript.splice(index, 1);
                this.dataSource = new MatTableDataSource<EventScript>(this.eventsScript);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        });
    }

    createScript() {
        const script: Script = {
            name: this.scriptFormGroup.get('nameCtrl').value,
            profileId: this.scriptFormGroup.get('profileCtrl').value,
            time: this.timePipe.transform(
                this.scriptFormGroup.get('timeCtrl').value,
                'inverse',
                this.scriptFormGroup.get('timeTypeCtrl').value),
            description: this.scriptFormGroup.get('descriptionCtrl').value
        };
        this.apiService.createScript(script).subscribe(response => {
            if (response.success) {
                if (this.eventsScript.length > 0) {
                    this.createEvents(response.data);
                } else {
                    this.openSnackBar(response.message, 'Aceptar');
                    this.router.navigate(['../'], {relativeTo: this.route}).then();
                }
            } else {
                this.openSnackBar(response.message, 'Aceptar');
            }
        }, error => {
            if (error) {
                this.openSnackBar('Ha ocurrido un error al registrar el guión', 'Aceptar');
            }
        });
    }

    createEvents(script) {
        for (let i = 0; i < this.eventsScript.length; i++) {
            this.eventsScript[i].scriptId = script.id;
            this.eventsScript[i].value = JSON.stringify(this.eventsScript[i].value);
        }
        this.apiService.createEventsScript(this.eventsScript).subscribe(response => {
            if (response.success) {
                this.openSnackBar('El guión junto con sus eventos ha sido registrado con éxito', 'Aceptar');
                this.router.navigate(['../'], {relativeTo: this.route}).then();
            } else {
                this.openSnackBar('No se han podido registrar los eventos del guión', 'Aceptar');
            }
        }, error => {
            if (error) {
                this.openSnackBar('Ha ocurrido un error al registrar los eventos del guión', 'Aceptar');
            }
        });
    }
}
