import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../../../services/api.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {NewAlternativeEventScriptComponent} from '../new-alternative-event-script/new-alternative-event-script.component';
import {EditAlternativeEventScriptComponent} from '../edit-alternative-event-script/edit-alternative-event-script.component';
import {RemoveAlternativeEventScriptComponent} from '../remove-alternative-event-script/remove-alternative-event-script.component';
import {
    EventScript,
    EventScriptAlternative,
    EventScriptDependence
} from '../../../../../../models/EventScript';
import {TimePipe} from '../../../../../../pipes/time.pipe';
import {EventType} from "../../../../../../models/EventType";
import {Event} from "../../../../../../models/Event";
import {Profile} from '../../../../../../models/Profile';
import {SimulationRoleCommand} from '../../../../../../models/SimulationRoleCommand';
import {SimulationRoleLevel} from '../../../../../../models/SimulationRoleLevel';
import {DisasterCell, DisasterCellLine} from "../../../../../../models/Disaster";

@Component({
    selector: 'app-new-event-script',
    templateUrl: './new-event-script.component.html',
    styleUrls: ['./new-event-script.component.scss']
})
export class NewEventScriptComponent implements OnInit {
    eventFormGroup: FormGroup;
    eventScriptFormGroup: FormGroup;
    disasterId;
    profileId;
    profile: Profile;
    scriptId;
    eventsScript: EventScript[];
    eventTypes: EventType[] = [];
    events: Event[] = [];
    eventsFiltered: Event[] = [];
    dependencies: EventScriptDependence[] = [];
    dependenciesSelected: EventScriptDependence[] = [];
    alternatives: EventScriptAlternative[] = [];
    commands: SimulationRoleCommand[] = [];
    levels: SimulationRoleLevel[] = [];
    directTo = [];
    visibleTo = [];
    evaluated: any = {};
    items = [];
    validTime = true;
    maxTime;
    timeType = [
        // {
        //    name: 'Segundos',
        //    value: 'seconds'
        // },
        {
            name: 'Minutos',
            value: 'minutes'
        },
        {
            name: 'Horas',
            value: 'hours'
        },
        {
            name: 'Días',
            value: 'days'
        },
    ];
    displayedColumns = ['name', 'dependencies', 'action'];
    dataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    // Mapa para foco de desastre
    disaster = [];
    colors = ['white', 'yellow', 'red', 'black', 'brown'];
    disasterCells: DisasterCell[] = [];
    dcph: DisasterCellLine[] = [];
    dcpv: DisasterCellLine[] = [];
    lat = -35.4519;
    lng = -72.2773;
    latUL = -35.3803;
    lngUL = -72.3652;
    latBR = -35.5235;
    lngBR = -72.1894;
    n_size: number;
    drawLines = true;

    constructor(public dialogRef: MatDialogRef<NewEventScriptComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar,
                private dialog: MatDialog,
                private apiService: ApiService,
                private timePipe: TimePipe) {
        if (this.data.scriptId) {
            this.scriptId = this.data.scriptId;
        } else {
            this.eventsScript = this.data.eventsScript;
        }
        this.disasterId = this.data.disasterId;
        this.dialogRef.disableClose = true;
        this.maxTime = this.data.maxTime;
        this.profileId = this.data.profileId;
        this.eventFormGroup = this.formBuilder.group({
            eventTypeCtrl: ['', Validators.required],
            eventCtrl: [{value: '', disabled: true}, Validators.required]
        });
    }

    ngOnInit() {
        this.apiService.getEventTypes().subscribe(response => {
            if (response.success) {
                this.eventTypes = response.data;
            }
        }, error => {
            if (error) {
            }
        });
        this.apiService.getEvents().subscribe(response => {
            if (response.success) {
                this.events = response.data;
            }
        }, error => {
            if (error) {
            }
        });
        this.apiService.getProfile(this.profileId).subscribe(response => {
            if (response.success) {
                this.profile = response.data;
                this.profile.cells = JSON.parse(response.data.cells);
                this.n_size = this.profile.size;
                const cellsStates = this.loadCellsDisaster(this.n_size, this.profile.cells);
                this.render(cellsStates);
                this.apiService.getVariablesByDisaster(this.disasterId).subscribe(response2 => {
                    if (response2.success) {
                        this.dependencies = response2.data;
                    }
                }, error2 => {
                    if (error2) {
                    }
                });
            }
        }, error => {
            if (error) {
            }
        });
        this.apiService.getSimulationRoleCommands().subscribe(response => {
            if (response.success) {
                this.commands = response.data;
                this.apiService.getSimulationRoleLevels().subscribe(response2 => {
                    if (response2.success) {
                        this.levels = response2.data;
                        for (const command of this.commands) {
                            this.directTo.push({
                                id: command.id,
                                name: command.name,
                                levels: []
                            });
                            this.visibleTo.push({
                                id: command.id,
                                name: command.name,
                                levels: []
                            });
                            for (const level of this.levels) {
                                this.directTo[this.directTo.length - 1].levels.push({
                                    id: level.id,
                                    name: level.name,
                                    selected: false
                                });
                                this.visibleTo[this.visibleTo.length - 1].levels.push({
                                    id: level.id,
                                    name: level.name,
                                    selected: false
                                });
                            }
                        }
                        this.items = JSON.parse(JSON.stringify(this.visibleTo));
                    }
                }, error => {
                    if (error) {
                    }
                });
            }
        }, error => {
            if (error) {
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    getEvents() {
        this.eventsFiltered = this.events.filter(result => result.eventTypeId === this.eventFormGroup.get('eventTypeCtrl').value.id);
        this.eventFormGroup.patchValue({
            eventCtrl: ''
        });
        this.eventFormGroup.get('eventCtrl').enable();
    }

    compareTimes() {
        if (this.eventFormGroup.get('eventTypeCtrl').value.name !== 'Salto de tiempo') {
            const time = this.timePipe.transform(
                this.eventScriptFormGroup.get('timeCtrl').value,
                'inverse',
                this.eventScriptFormGroup.get('timeTypeCtrl').value
            );
            this.validTime = time <= this.maxTime;
        } else {
            const time = this.timePipe.transform(
                this.eventScriptFormGroup.get('timeCtrl').value,
                'inverse',
                this.eventScriptFormGroup.get('timeTypeCtrl').value
            );
            const jumpTime = this.timePipe.transform(
                this.eventScriptFormGroup.get('jumpTimeCtrl').value,
                'inverse',
                this.eventScriptFormGroup.get('jumpTimeTypeCtrl').value
            );
            this.validTime = time + jumpTime <= this.maxTime;
        }
    }

    initialEventScript() {
        this.validTime = true;
        switch (this.eventFormGroup.get('eventTypeCtrl').value.name) {
            case 'Desastre':
                this.eventScriptFormGroup = this.formBuilder.group({
                    nameCtrl: ['', Validators.required],
                    descriptionCtrl: ['', Validators.required],
                    valueCtrl: ['', Validators.required],
                    timeCtrl: ['', Validators.required],
                    timeTypeCtrl: ['', Validators.required],
                    youtubeCtrl: ['']
                });
                this.eventScriptFormGroup.patchValue({
                    nameCtrl: this.eventFormGroup.get('eventCtrl').value.name,
                    descriptionCtrl: this.eventFormGroup.get('eventCtrl').value.description,
                    valueCtrl: this.eventFormGroup.get('eventCtrl').value.value,
                    timeTypeCtrl: this.timeType[0].value
                });
                break;
            case 'Salto de tiempo':
                this.eventScriptFormGroup = this.formBuilder.group({
                    nameCtrl: ['', Validators.required],
                    descriptionCtrl: ['', Validators.required],
                    timeCtrl: ['', Validators.required],
                    timeTypeCtrl: ['', Validators.required],
                    jumpTimeCtrl: ['', Validators.required],
                    jumpTimeTypeCtrl: ['', Validators.required],
                    youtubeCtrl: ['']
                });
                this.eventScriptFormGroup.patchValue({
                    nameCtrl: this.eventFormGroup.get('eventCtrl').value.name,
                    descriptionCtrl: this.eventFormGroup.get('eventCtrl').value.description,
                    timeTypeCtrl: this.timeType[0].value,
                    jumpTimeTypeCtrl: this.timeType[0].value
                });
                break;
            case 'Toma de decision':
                this.eventScriptFormGroup = this.formBuilder.group({
                    nameCtrl: ['', Validators.required],
                    descriptionCtrl: ['', Validators.required],
                    timeCtrl: ['', Validators.required],
                    timeTypeCtrl: ['', Validators.required],
                    youtubeCtrl: ['']
                });
                this.eventScriptFormGroup.patchValue({
                    nameCtrl: this.eventFormGroup.get('eventCtrl').value.name,
                    descriptionCtrl: this.eventFormGroup.get('eventCtrl').value.description,
                    timeTypeCtrl: this.timeType[0].value
                });
                break;
        }
    }

    newAlternative() {
        const dialogRef = this.dialog.open(NewAlternativeEventScriptComponent, {
            maxWidth: '600px',
            width: '95%',
            autoFocus: false,
            data: {
                profileId: this.profileId,
                disasterId: this.disasterId
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                //this.alternatives.unshift(result);
                this.alternatives.push(result);
                this.dataSource = new MatTableDataSource<EventScriptAlternative>(this.alternatives);
                this.dataSource.sort = this.sort;
            }
        });
    }

    editAlternative(alternative, index) {
        const dialogRef = this.dialog.open(EditAlternativeEventScriptComponent, {
            maxWidth: '600px',
            width: '95%',
            autoFocus: false,
            data: {
                alternative: alternative,
                profileId: this.profileId,
                disasterId: this.disasterId
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.alternatives[index] = result;
                this.dataSource = new MatTableDataSource<EventScriptAlternative>(this.alternatives);
                this.dataSource.sort = this.sort;
            }
        });
    }

    deleteAlternative(alternative, index) {
        const dialogRef = this.dialog.open(RemoveAlternativeEventScriptComponent, {
            maxWidth: '600px',
            width: '95%',
            autoFocus: false,
            data: {
                alternative: alternative
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.alternatives.splice(index, 1);
                this.dataSource = new MatTableDataSource<EventScriptAlternative>(this.alternatives);
                this.dataSource.sort = this.sort;
            }
        });
    }

    createEventScript() {
        if (this.eventFormGroup.valid && this.eventScriptFormGroup.valid) {
            if (this.validTime === true) {
                let eventScript: EventScript;
                if (this.eventFormGroup.get('eventTypeCtrl').value.name === 'Desastre') {
                    eventScript = {
                        eventId: this.eventFormGroup.get('eventCtrl').value.id,
                        name: this.eventScriptFormGroup.get('nameCtrl').value,
                        description: this.eventScriptFormGroup.get('descriptionCtrl').value,
                        time: this.timePipe.transform(
                            this.eventScriptFormGroup.get('timeCtrl').value,
                            'inverse',
                            this.eventScriptFormGroup.get('timeTypeCtrl').value),
                        value: {
                            value: this.eventScriptFormGroup.get('valueCtrl').value,
                            yurl: this.eventScriptFormGroup.get('youtubeCtrl').value,
                            dependencies: this.dependenciesSelected,
                            visibleTo: []
                        }
                    };
                    if (this.eventFormGroup.get('eventCtrl').value.id === 7) {
                        const cells = [];
                        this.disasterCells.forEach(cell => {
                            if (cell.state === 2 && cell.isNew) {
                                cells.push({
                                    id: cell.id,
                                    state: cell.state
                                });
                            }
                        });
                        eventScript.value.cells = cells;
                    }
                    for (let i = 0; i < this.visibleTo.length; i++) {
                        eventScript.value.visibleTo.push({
                            id: this.visibleTo[i].id,
                            name: this.visibleTo[i].name,
                            levels: []
                        });
                        for (let j = 0; j < this.visibleTo[i].levels.length; j++) {
                            if (this.visibleTo[i].levels[j].selected === true) {
                                eventScript.value.visibleTo[i].levels.push({
                                    id: this.visibleTo[i].levels[j].id,
                                    name: this.visibleTo[i].levels[j].name
                                });
                            }
                        }
                    }
                    this.dialogRef.close({data: eventScript});
                } else if (this.eventFormGroup.get('eventTypeCtrl').value.name === 'Salto de tiempo') {
                    eventScript = {
                        eventId: this.eventFormGroup.get('eventCtrl').value.id,
                        name: this.eventScriptFormGroup.get('nameCtrl').value,
                        description: this.eventScriptFormGroup.get('descriptionCtrl').value,
                        time: this.timePipe.transform(
                            this.eventScriptFormGroup.get('timeCtrl').value,
                            'inverse',
                            this.eventScriptFormGroup.get('timeTypeCtrl').value),
                        value: {
                            value: this.timePipe.transform(
                                this.eventScriptFormGroup.get('jumpTimeCtrl').value,
                                'inverse',
                                this.eventScriptFormGroup.get('jumpTimeTypeCtrl').value),
                            yurl: this.eventScriptFormGroup.get('youtubeCtrl').value,
                            dependencies: this.dependenciesSelected,
                            visibleTo: [],
                            directTo: []
                        }
                    };
                    for (let i = 0; i < this.visibleTo.length; i++) {
                        eventScript.value.visibleTo.push({
                            id: this.visibleTo[i].id,
                            name: this.visibleTo[i].name,
                            levels: []
                        });
                        for (let j = 0; j < this.visibleTo[i].levels.length; j++) {
                            if (this.visibleTo[i].levels[j].selected === true) {
                                eventScript.value.visibleTo[i].levels.push({
                                    id: this.visibleTo[i].levels[j].id,
                                    name: this.visibleTo[i].levels[j].name
                                });
                            }
                        }
                    }
                    for (let i = 0; i < this.directTo.length; i++) {
                        eventScript.value.directTo.push({
                            id: this.directTo[i].id,
                            name: this.directTo[i].name,
                            levels: []
                        });
                        for (let j = 0; j < this.directTo[i].levels.length; j++) {
                            if (this.directTo[i].levels[j].selected === true) {
                                eventScript.value.directTo[i].levels.push({
                                    id: this.directTo[i].levels[j].id,
                                    name: this.directTo[i].levels[j].name
                                });
                            }
                        }
                    }
                    this.dialogRef.close({data: eventScript});
                } else if (this.eventFormGroup.get('eventTypeCtrl').value.name === 'Toma de decision') {
                    eventScript = {
                        eventId: this.eventFormGroup.get('eventCtrl').value.id,
                        name: this.eventScriptFormGroup.get('nameCtrl').value,
                        description: this.eventScriptFormGroup.get('descriptionCtrl').value,
                        time: this.timePipe.transform(
                            this.eventScriptFormGroup.get('timeCtrl').value,
                            'inverse',
                            this.eventScriptFormGroup.get('timeTypeCtrl').value),
                        value: {
                            yurl: this.eventScriptFormGroup.get('youtubeCtrl').value,
                            alternatives: this.alternatives,
                            visibleTo: [],
                            directTo: []
                        }
                    };
                    for (let i = 0; i < this.visibleTo.length; i++) {
                        eventScript.value.visibleTo.push({
                            id: this.visibleTo[i].id,
                            name: this.visibleTo[i].name,
                            levels: []
                        });
                        for (let j = 0; j < this.visibleTo[i].levels.length; j++) {
                            if (this.visibleTo[i].levels[j].selected === true) {
                                eventScript.value.visibleTo[i].levels.push({
                                    id: this.visibleTo[i].levels[j].id,
                                    name: this.visibleTo[i].levels[j].name
                                });
                            }
                        }
                    }
                    for (let i = 0; i < this.directTo.length; i++) {
                        eventScript.value.directTo.push({
                            id: this.directTo[i].id,
                            name: this.directTo[i].name,
                            levels: []
                        });
                        for (let j = 0; j < this.directTo[i].levels.length; j++) {
                            if (this.directTo[i].levels[j].selected === true) {
                                eventScript.value.directTo[i].levels.push({
                                    id: this.directTo[i].levels[j].id,
                                    name: this.directTo[i].levels[j].name
                                });
                            }
                        }
                    }
                    if(this.evaluated && this.evaluated.by){
                        eventScript.value.evaluatedBy = this.evaluated.by;
                        eventScript.value.evaluatedBy.item = this.evaluated.item;
                        delete eventScript.value.evaluatedBy.item.levels;
                    }
                    this.dialogRef.close({data: eventScript});
                } else {
                    this.openSnackBar('Falta información para crear el evento', 'Aceptar');
                }
            } else {
                this.openSnackBar('El tiempo de ejecución del evento no puede ser mayor al de la duración del guión', 'Aceptar');
            }
        } else {
            this.openSnackBar('Faltan campos por completar', 'Aceptar');
        }
    }

    evaluatedChange(event, item){
        this.evaluated.item = item;
    }

    /**
     *
     * Funciones para el MAPA
     */
    getLastFocusDisasterEvent() {
        if (this.scriptId) {
            this.apiService.getEventsScriptByScript(this.scriptId).subscribe(response => {
                if (response.state === 1) {
                    this.eventsScript = response.data;
                    let eventScript: EventScript;
                    for (let i = 0; i < this.eventsScript.length; i++) {
                        if (this.eventsScript[i].eventId === 7) {
                            if (eventScript && eventScript.time < this.eventsScript[i].time) {
                                eventScript = this.eventsScript[i];
                            }
                        }
                    }
                    if (eventScript) {
                        for (let i = 0; i < this.profile.cells.length; i++) {
                            for (let j = 0; j < eventScript.value.cells.length; j++) {
                                if (this.profile.cells[i].id === eventScript.value.cells[j].id) {
                                    this.profile.cells[i] = eventScript.value.cells[j];
                                }
                            }
                        }
                        const cellsStates = this.loadCellsDisaster(this.n_size, this.profile.cells);
                        this.render(cellsStates);
                    }
                }
            }, error => {
                if (error) {
                }
            });
        } else {
            let eventScript: EventScript;
            for (let i = 0; i < this.eventsScript.length; i++) {
                if (this.eventsScript[i].eventId === 7) {
                    if (eventScript && eventScript.time < this.eventsScript[i].time &&
                        this.validTime) {
                        eventScript = this.eventsScript[i];
                    } else if (!eventScript && this.validTime) {
                        eventScript = this.eventsScript[i];
                    }
                }
            }
            if (eventScript) {
                const newCells = this.profile.cells;
                for (let i = 0; i < newCells.length; i++) {
                    for (let j = 0; j < eventScript.value.cells.length; j++) {
                        if (newCells[i].id === eventScript.value.cells[j].id) {
                            newCells[i] = eventScript.value.cells[j];
                        }
                    }
                }
                this.clear();
                const cellsStates = this.loadCellsDisaster(this.n_size, newCells);
                this.render(cellsStates);
            }
        }
    }

    createGrid() {
        this.dcph = [];
        this.dcpv = [];

        const dLat = (this.latBR - this.latUL) / this.n_size;
        const dLng = (this.lngBR - this.lngUL) / this.n_size;

        for (let i = 0; i < this.n_size + 1; i++) {
            // Horizontal points
            this.dcph.push({
                id: 'fcph' + i,
                latA: this.latUL + dLat * i, // dLatLng[0]*i,
                lngA: this.lngUL,
                latB: this.latUL + dLat * i, // dLatLng[0]*i,
                lngB: this.lngBR
            });

            // Vertical points
            this.dcpv.push({
                id: 'fcpv' + i,
                latA: this.latUL,
                lngA: this.lngUL + dLng * i, // dLatLng[1]*i,
                latB: this.latBR,
                lngB: this.lngUL + dLng * i, // dLatLng[1]*i
            });
        }
    }

    mapClicked($event: any) {
        // Out of map
        if (!this.checkBorders($event.coords.lat, $event.coords.lng)) {
            return;
        }

        const rc = this.getRowCol($event.coords.lat, $event.coords.lng);
        const id = this.getID(rc[0], rc[1]);
        const fc = this.searchDisasterCellsById(id);
        if (fc.cell != null) {
            fc.cell.state = this.checkState(fc.cell.state, fc.index);
        } else {
            this.disasterCells.push({
                id: id,
                path: this.cellPathID(id),
                state: 1
            });
        }

        this.clearCells(0); // Remove cells with state 0
    }

    mapZoom($event: any) {

        if (this.n_size !== undefined) {
            if (this.n_size <= 50) {
                this.drawLines = $event > 8;
            } else if (this.n_size <= 100) {
                this.drawLines = $event > 13;
            } else if (this.n_size <= 750) {
                this.drawLines = $event > 15;
            } else {
                this.drawLines = $event > 17;
            }
        }
    }


    checkBorders(lat, lng) {
        return lat <= this.latUL && lat >= this.latBR && lng >= this.lngUL && lng <= this.lngBR;
    }

    getRowCol(lat, lng) {
        const tc = this.cellSize();
        const metersLatLng = this.deltaLatLngToMeters(lat, lng);
        const i = Math.floor(metersLatLng[1] / tc[1]);
        const j = Math.floor(metersLatLng[0] / tc[0]);
        return [i, j];
    }

    getID(i, j) {
        return i * this.n_size + j;
    }

    searchDisasterCellsById(id) {
        let fc = null;
        for (let i = 0; i < this.disasterCells.length; i++) {
            if (this.disasterCells[i].id === id) {
                fc = {cell: this.disasterCells[i], index: i};
                break;
            }
        }
        return fc;
    }

    checkState(state, index) {
        let rs = 0;
        if (state === 1) {
            rs = 2;
            this.disasterCells[index].isNew = true;
        } else if (state === 2) {
            if (this.disasterCells[index].isNew) {
                rs = 1;
            } else {
                rs = 2;
            }
        }
        return rs;
    }

    cellPathID(id) {
        const ij = this.getIJ(id);
        const borders = this.cellBorders(ij[0], ij[1]);
        return [
            {lat: borders[0], lng: borders[1]},
            {lat: borders[0], lng: borders[3]},
            {lat: borders[2], lng: borders[3]},
            {lat: borders[2], lng: borders[1]},
            {lat: borders[0], lng: borders[1]}
        ];
    }

    clear() {
        this.disasterCells = [];
        this.dcph = [];
        this.dcpv = [];
        this.drawLines = false;

    }

    cellSize() {
        const metersLatLng = this.deltaLatLngToMeters(this.latBR, this.lngBR);
        return [metersLatLng[0] / this.n_size, metersLatLng[1] / this.n_size];
    }

    clearCells(state) {
        this.disaster = this.disaster.filter(function (e) {
            return e.state !== state;
        });
    }

    deltaLatLngToMeters(lat, lng) {
        const deltaLatitude = lat - this.latUL;
        const deltaLongitude = lng - this.lngUL;
        const latitudeCircumference = 40075160 * Math.cos(this.asRadians(this.latUL));
        const resultX = Math.abs(deltaLongitude * latitudeCircumference / 360);
        const resultY = Math.abs(deltaLatitude * 40008000 / 360);
        return [resultX, resultY];
    }

    getIJ(id) {
        const i = Math.floor(parseInt(id, 10) / this.n_size);
        const j = Number(parseInt(id, 10) % this.n_size);
        return [i, j];
    }

    cellBorders(i, j) {
        const tc = this.cellSizeLatLng();
        const latA = this.latUL + tc[0] * i;
        const lngA = this.lngUL + tc[1] * j;
        const latB = this.latUL + tc[0] * (i + 1);
        const lngB = this.lngUL + tc[1] * (j + 1);
        return [latA, lngA, latB, lngB];
    }

    asRadians(degrees: number) {
        return degrees * Math.PI / 180;
    }

    cellSizeLatLng() {
        const dLat = (this.latBR - this.latUL) / this.n_size;
        const dLng = (this.lngBR - this.lngUL) / this.n_size;
        return [dLat, dLng];
    }

    loadCellsDisaster(size, cells) {
        const cellsStates = [];
        for (let i = 0; i < size * size; i++) {
            cellsStates.push(0);
        }
        cells.forEach(cell => {
            cellsStates[cell.id] = cell.state;
        });
        return cellsStates;
    }

    render(fcs) {
        for (let i = 0; i < fcs.length; i++) {
            if (fcs[i] > 0) {
                this.disasterCells.push({
                    id: i,
                    path: this.cellPathID(i),
                    state: fcs[i]
                });
            }
        }
    }

}
