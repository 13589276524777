import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSnackBar} from '@angular/material';
import {ApiService} from '../../../../../../../services/api.service';

@Component({
  selector: 'app-alternatives-simulation',
  templateUrl: './alternatives-simulation.component.html',
  styleUrls: ['./alternatives-simulation.component.scss']
})
export class AlternativesSimulationComponent implements OnInit, OnDestroy  {

  private selected: any;
  private otherValue: string;
  private observation: string;
  private observation2: string;
  private evaluationValues = [
    {id: 1, name: "Muy Desacuerdo"},
    {id: 2, name: "Desacuerdo"},
    {id: 3, name: "Regular"},
    {id: 4, name: "De Acuerdo"},
    {id: 5, name: "Muy de Acuerdo"}
  ];
  private evaluation: any;
  private reEvaluation: any;
  public hasVideo: boolean = false;
  
  constructor(public dialogRef: MatDialogRef<AlternativesSimulationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              @Inject('Window') window: Window,
              private apiService: ApiService) {
      dialogRef.disableClose = true;
      console.log(data);
      this.selected = {};
      this.otherValue = "";
      if(data.yurl){
        this.hasVideo = true;
      }
      if(this.data.edit){
        if(this.data.alternative.eventValue){
          if(this.data.alternative.eventValue.alternatives){
            this.data.alternatives = this.data.alternative.eventValue.alternatives;
          }
        }
        this.otherValue = this.data.alternative.otherValue;
        if(this.data.alternative.valueEvaluated > 0){
          for(let e of this.evaluationValues){
            if(this.data.alternative.valueEvaluated == e.id){
              this.evaluation = e.id;
              break;
            }
          }
        }
        if(this.data.alternatives){
          for(let a of this.data.alternatives){
            if(a.name == this.data.alternative.selected){
              this.selected = a;
              break;
            }
          }
        }
      }
      if(this.data.re){
        if(this.data.alternative.valueReEvaluated > 0){
          for(let e of this.evaluationValues){
            if(this.data.alternative.valueReEvaluated == e.id){
              this.reEvaluation = e.id;
              break;
            }
          }
        }
      }
  }

  stopMainVideo(){
    window["$"]('iframe.main-embed-video')[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
  }


  ngOnInit() {
    window['magic'] = 0;
    if(this.hasVideo){
      for(let ms of [500, 1000, 1500, 3000]){
        setTimeout(this.stopMainVideo, ms);
      }
    }
    this.createStyle();
  }

  createStyle(){
    var $ = window["$"];
    if(!$("#custom_style_popup").length){
      $("body").append("<style id='custom_style_popup'></style>");
    }
    $("#custom_style_popup").html(".cdk-global-overlay-wrapper {    pointer-events: auto;    display: block;    position: relative !important;    overflow: auto !important;    text-align: center;  }    .cdk-global-overlay-wrapper::before {    content: '';    display: inline-block;    height: 100%;    white-space: nowrap;  }    .cdk-overlay-pane {    display: inline-block;    position: relative !important;    text-align: left;    white-space: normal;  }");
  }


  ngOnDestroy(){
    this.removeStyle();
  }
  removeStyle(){
    var $ = window["$"];
    $("#custom_style_popup").remove();
  }


  turnAlternative(a){
    if(a){
      this.otherValue = "";
    }else{
      this.selected = {name: ""};
    }
  }

  confirmAlternative(alternatives) {
    
      if(this.data.edit ){
        if(this.data.re){
          let alternative = {
            id: this.data.alternative.id,
            reEvaluatedById: this.data.user,
            valueReEvaluated: this.reEvaluation,
            obsReEvaluated: this.observation2
          };
          this.data.alternative.valueReEvaluated = this.reEvaluation;
          this.apiService.updateAlternativeEvaluation(alternative).subscribe(response => {
            if(this.data.done){
              this.data.done(this.data);
            }
          });
        }else{
          let alternative = {
            id: this.data.alternative.id,
            evaluatedById: this.data.simulationRole.id,
            valueEvaluated: this.evaluation,
            obsEvaluated: this.observation
          };
          this.data.alternative.valueEvaluated = this.evaluation;
          this.apiService.updateAlternative(alternative).subscribe(response => {
  
          });
        }
        
      }else{
        let alternative = {
          idEventScript: this.data.id_guion,
          idSimulation: this.data.idSimulation,
          idUser: this.data.simulationRole.id,
          selected: alternatives.name,
          otherValue: this.otherValue,
          evaluatedById: -1,
          valueEvaluated: -1
        };
        this.apiService.createAlternative(alternative).subscribe(response => {

        });

        if(this.data.done){
          this.data.done(this.data);
        }
      }
      
      this.dialogRef.close();
  }
}
