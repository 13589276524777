import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
    name: 'urlYoutube'
})
export class UrlYoutubePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(embed) {
        console.log(embed);

       // return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + embed + '?autoplay=1');
       return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + embed + '?autoplay=1&enablejsapi=1');
    }

}
