import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.authService.getUser().subscribe(response => {
            if (response.success) {
                return true;
            }
            this.router.navigate(['..'], {relativeTo: this.route}).then();
            return false;
        }, error => {
            if (error) {
                this.router.navigate(['..'], {relativeTo: this.route}).then();
                return false;
            }
        });
        return true;
    }
}
