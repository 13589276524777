import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {User} from '../../../models/User';
import {ActivatedRoute, Router} from '@angular/router';
import {RemoveUserComponent} from './dialogs/remove-user/remove-user.component';
import {EditUserComponent} from './dialogs/edit-user/edit-user.component';
import {NewUserComponent} from './dialogs/new-user/new-user.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    title = '';
    filter = '';
    displayedColumns = ['row', 'userName', 'firstName', 'email', 'roleName', 'action'];
    dataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    users: User[] = [];

    constructor(private dialog: MatDialog,
                private router: Router,
                private route: ActivatedRoute,
                private apiService: ApiService) {
        this.title = this.route.snapshot.data.title;
    }

    ngOnInit() {
        this.apiService.getUsers().subscribe(response => {
            if (response.success) {
                this.users = response.data;
                this.dataSource = new MatTableDataSource<User>(this.users);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }, error => {
            if (error) {
            }
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    createUser() {
        const dialogRef = this.dialog.open(NewUserComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

    updateUser(user: User) {
        const dialogRef = this.dialog.open(EditUserComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                user: user
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

    deleteUser(user: User) {
        const dialogRef = this.dialog.open(RemoveUserComponent, {
            maxWidth: '750px',
            width: '98%',
            autoFocus: false,
            data: {
                user: user
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }
}
