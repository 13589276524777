import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Script} from '../../../../../../models/Script';
import {Scenario} from '../../../../../../models/Scenario';
import {Profile} from '../../../../../../models/Profile';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TimePipe} from '../../../../../../pipes/time.pipe';
import {ApiService} from '../../../../../../services/api.service';

@Component({
    selector: 'app-edit-script',
    templateUrl: './edit-script.component.html',
    styleUrls: ['./edit-script.component.scss']
})
export class EditScriptComponent implements OnInit {
    scriptFormGroup: FormGroup;
    script: Script;
    id;
    disasterId;
    scenarios: Scenario[] = [];
    profiles: Profile[] = [];
    timeType = [
        {
            name: 'Segundos',
            value: 'seconds'
        },
        {
            name: 'Minutos',
            value: 'minutes'
        },
        {
            name: 'Horas',
            value: 'hours'
        },
        {
            name: 'Días',
            value: 'days'
        },
    ];

    constructor(public dialogRef: MatDialogRef<EditScriptComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar,
                private dialog: MatDialog,
                private apiService: ApiService,
                private timePipe: TimePipe) {
        this.id = this.data.id;
        this.disasterId = this.data.disasterId;
        this.scriptFormGroup = this.formBuilder.group({
            nameCtrl: ['', Validators.required],
            scenarioCtrl: ['', Validators.required],
            profileCtrl: ['', Validators.required],
            timeCtrl: ['', Validators.required],
            timeTypeCtrl: ['', Validators.required],
            descriptionCtrl: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.apiService.getScenariosByDisaster(this.disasterId).subscribe(response => {
            if (response.success) {
                this.scenarios = response.data;
                this.apiService.getProfilesByDisaster(this.disasterId).subscribe(response2 => {
                    if (response2.success) {
                        this.profiles = response2.data;
                        this.apiService.getScript(this.id).subscribe(response3 => {
                            if (response3.success) {
                                this.script = response3.data;
                                this.scriptFormGroup.patchValue({
                                    nameCtrl: this.script.name,
                                    profileCtrl: this.script.profileId,
                                    timeCtrl: this.timePipe.transform(this.script.time, this.timeType[1].value),
                                    timeTypeCtrl: this.timeType[1].value,
                                    descriptionCtrl: this.script.description
                                });
                                const myProfile: Profile = this.profiles.find(profile => profile.id === this.script.profileId);
                                const scenario: Scenario = this.scenarios.find(scenario => scenario.id === myProfile.scenarioId);
                                if (scenario) {
                                    this.scriptFormGroup.get('scenarioCtrl').setValue(scenario.id);
                                    this.loadProfiles();
                                }
                            } else {
                                this.dialogRef.close();
                            }
                        }, error => {
                            if (error) {
                                this.dialogRef.close();
                            }
                        });
                    }
                }, error => {
                    if (error) {
                    }
                });
            }
        }, error => {
            if (error) {
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    loadProfiles() {
        this.scriptFormGroup.get('profileCtrl').reset();
        this.apiService.getProfilesByScenario(this.scriptFormGroup.get('scenarioCtrl').value).subscribe(response => {
            if (response.success) {
                this.profiles = response.data;
                const profile: Profile = this.profiles.find(profile => profile.id === this.script.profileId);
                if (profile) {
                    this.scriptFormGroup.get('profileCtrl').setValue(profile.id);
                }
            }
        })
    }

    updateScript() {
        const script: Script = {
            id: this.script.id,
            name: this.scriptFormGroup.get('nameCtrl').value,
            description: this.scriptFormGroup.get('descriptionCtrl').value,
            time: Math.round(this.timePipe.transform(
                this.scriptFormGroup.get('timeCtrl').value,
                'inverse',
                this.scriptFormGroup.get('timeTypeCtrl').value
            )),
            profileId: this.scriptFormGroup.get('profileCtrl').value,
        };
        this.apiService.updateScript(this.id, script).subscribe(response => {
            if (response.success) {
                this.openSnackBar(response.message, 'Aceptar');
                this.dialogRef.close(true);
            } else {
                this.openSnackBar(response.message, 'Aceptar');
            }
        }, error => {
            if (error) {
                this.openSnackBar('Ha ocurrido un error al actualizar el guión', 'Aceptar');
            }
        });
    }

}
