import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../../services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {User} from '../../../../../models/User';

@Component({
    selector: 'app-new-user',
    templateUrl: './new-user.component.html',
    styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
    userFormGroup: FormGroup;
    roles = [];

    constructor(public dialogRef: MatDialogRef<NewUserComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar) {
        this.dialogRef.disableClose = true;
        this.userFormGroup = this.formBuilder.group({
            nameCtrl: ['', Validators.required],
            firstNameCtrl: ['', Validators.required],
            lastNameCtrl: ['', Validators.required],
            emailCtrl: ['', Validators.required],
            passwordCtrl: ['', Validators.required],
            roleCtrl: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.apiService.getRoles().subscribe(response => {
            if (response.success) {
                this.roles = response.data;
            } else {
                this.dialogRef.close();
            }
        }, error => {
            if (error) {
                this.dialogRef.close();
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3500,
        });
    }

    createUser() {
        if (this.userFormGroup.valid) {
            const user: User = {
                userName: this.userFormGroup.get('nameCtrl').value,
                firstName: this.userFormGroup.get('firstNameCtrl').value,
                lastName: this.userFormGroup.get('lastNameCtrl').value,
                email: this.userFormGroup.get('emailCtrl').value,
                password: this.userFormGroup.get('passwordCtrl').value,
                roleId: this.userFormGroup.get('roleCtrl').value
            };
            this.apiService.createUser(user).subscribe(response => {
                if (response.success) {
                    this.openSnackBar(response.message, 'Aceptar');
                    this.dialogRef.close(true);
                } else {
                    this.openSnackBar(response.message, 'Aceptar');
                }
            }, error => {
                if (error) {
                    this.openSnackBar('No se ha podido registrar el usuario (ERROR)', 'Aceptar');
                }
            });
        } else {
            this.openSnackBar('Datos incompletos', 'Aceptar');
        }
    }

}
